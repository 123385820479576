<template>
  <div class="crown__impressum">
    <div id="bg">
      <img :src="img" alt="" />
    </div>
    <Menu />

    <div class="crown__datenschutz--block-aaa">
      <div class="crown__datenschutz--block-header">
        <div class="crown__house--block">
          <div class="crown__house--side">
            <div class="crown__house--side-home">
              <div class="crown__house--side-homename">strandkai</div>
              <div class="crown__house--side-homepage">
                <router-link class="menu__logo" to="/">
                  Impressum &#9660;
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="crown__house--block-bottom"></div>
      <div class="outerContainerDate">
        <div class="container">
          <p>Impressum:</p>
          <div class="container-overflow">
            <div class="imprint-text-container">
              PE Strandkai GmbH &amp; Co. KG
              <br />
              c/o DC DEVELOPMENTS GMBH &amp; CO. KG
              <br />Großer Grasbrook 9 <br />D-20457 Hamburg<br /><br />Handelsregister:
              HRA 121560<br />Registergericht: Amtsgericht Hamburg<br /><br />Komplementärin
              (phG):<br /><br />PE Strandkai Verwaltungs GmbH<br />c/o DEVELOPMENTS GMBH &amp; CO. KG<br />Großer Grasbrook 9<br />D-20457 Hamburg<br /><br />Geschäftsführer:
              Frank Holst und Lothar Schubert<br />Handelsregister: HRB
              143536<br />Registergericht: Amtsgericht Hamburg<br /><br />Kontakt:<br /><br />Telefon
              +49 (0)40 60 00 37-200<br />Telefax +49 (0)40 60 00 37-210<br />E-Mail
              info@dcdevelopments.de<br />Internet www.dcdevelopments.de<br />UMSATZ-ID
              gemäß § 27a Umsatzsteuergesetz: 48/612/03994<br /><br />Rechtliche Hinweise
              <br />Herausgeber der Website ist die PE Strandkai GmbH &amp; Co.
              KG, eine Projektgesellschaft der DC Developments GmbH &amp; Co. KG
              und AUG. PRIEN Immobilien Gesellschaft für Projektentwicklung mbH.
              Die Webseiten werden regelmäßig auf Aktualität der Inhalte
              überprüft. Trotz sorgfältiger Pflege der Seiten können sich
              zeitweise Differenzen ergeben. Aus diesem Grund kann keine Gewähr
              für Richtigkeit und Vollständigkeit der zur Verfügung gestellten
              Informationen übernommen werden. Alle Angebote auf dieser Website
              sind freibleibend. Änderungen der Inhalte dieser Website behalten
              wir uns ausdrücklich vor.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from './../components/Menu'
import imgOst from './../assets/kontakt/back-kontakt.jpg'

export default {
  data: function () {
    return {
      img: imgOst,
      info: '',
      text: ''
      //imgNord
    };
  },
  name: 'Impressum',
  props: {
    msg: String
  },
  components: {
    Menu
  }
}
</script>

<style scoped>
.crown__impressum {
  height: 100vh;
}
#bg {
  position: fixed;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#bg img {
  height: 100vh;
  width: 100vw;
}
.crown__datenschutz--block-header {
  height: 35px;
  width: 1178px;
  background: #fff;
}
.crown__datenschutz--block-aaa {
  position: fixed;
    height: 650px;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 4em;
    width: 1178px;
    z-index: 2;
}
.crown__datenschutz--block {
  width: 1178px;
  margin: 0 auto;
  position: relative;
}
.outerContainerDate {
  position: relative;
  height: calc(100% - 35px);
  overflow-y: auto;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(4px);
  text-align: center;
}
.container {
  position: relative;
  width: 100%;
  max-width: 60%;
  height: auto;
  display: block;
  margin: auto;
  margin-bottom: 4em;
}

.container h2 {
  font-family: "35-FTR", sans-serif;
  font-weight: 600;
}

.crown__house--block {
  position: fixed;
  width: 100%;
}
.crown__house--block-bottom {
  position: fixed;
  width: 1178px;
  bottom: 2em;
  z-index: 2;
  background: #fff;
  height: 35px;
}
.crown__house--side .crown__house--side-home {
  display: flex;
  flex-wrap: wrap;
  width: 1178px;
  justify-content: space-between;
  font-family: "35-FTR", sans-serif;
  font-size: 15px;
  letter-spacing: 0.06rem;
  box-sizing: border-box;
  height: 35px;
  align-items: center;
    justify-content: space-between;
}

.crown__house--side-homename {
  text-transform: uppercase;
  font-weight: bold;
  padding-left: 2em;
}
.crown__house--side-homepage {
  padding-right: 2em;
}
.crown__house--side-homepage a {
  color: #323a41;
  font-weight: 300;
}
</style>
