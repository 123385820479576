var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"moodboard"},[(_vm.loader == false)?_c('div',[_vm._v("Laden...")]):_vm._e(),(_vm.loader == true)?_c('div',[_vm._l((_vm.configuratorData),function(p,i){return _c('div',{key:i},[(_vm.visiblePersonal == p.header)?_c('div',[_c('div',{staticClass:"moodboard__check--header"},[(p.header == 'Malerarbeiten Wände')?_c('p',{staticClass:"menu__personal--item"},[_vm._v(" Wandfarben ")]):_vm._e(),(p.header == 'Parkettarbeiten')?_c('p',{staticClass:"menu__personal--item"},[_vm._v(" Boden "),_c('br'),_vm._v(" Mehrschichtparkett in Dielenoptick "),_c('br'),_vm._v(" Eiche geölt ")]):_vm._e(),(p.header == 'Türdrückergarnituren')?_c('p',{staticClass:"menu__personal--item"},[_vm._v(" Ausstattung ")]):_vm._e(),(p.header == 'Fliesenarbeiten Waschtisch')?_c('p',{staticClass:"menu__personal--item"},[_vm._v(" Fliesenfarben ")]):_vm._e(),(p.header == 'Waschtischunterschrank')?_c('p',{staticClass:"menu__personal--item"},[_vm._v(" Möbelfronten ")]):_vm._e(),(p.header == 'Waschtischanlage')?_c('p',{staticClass:"menu__personal--item"},[_vm._v(" Armaturen ")]):_vm._e()]),_c('div',{staticClass:"moodboard__check--panel"},_vm._l((p.productPrices),function(product,productIndex){return _c('div',{key:productIndex,staticClass:"moodboard__check"},[_c('div',{staticClass:"moodboard__check--item pointer",class:product.tags[0] != 'design_line_crown:pure' ? 'none' : '',on:{"click":function($event){_vm.addLink(p.header, product.product.id);
                _vm.select();}}},[_vm._l((product.product.images[0]),function(img,item){return _c('div',{key:item,staticClass:"moodboard__info--img",class:p.header == 'Türdrückergarnituren' ? 'turd' : ''},[_c('img',{staticClass:"round",attrs:{"src":img,"alt":""}})])}),_c('div',{staticClass:"moodboard__check--item-text"},[(
                    _vm.msg.room.name == 'Wohnen' && p.header == 'Parkettarbeiten'
                  )?_c('div',[_vm._v(" "+_vm._s(_vm.nameChangeLast(product.product.name))+" ")]):_vm._e(),(
                    _vm.msg.room.name == 'Wohnen' && p.header != 'Parkettarbeiten'
                  )?_c('div',[_vm._v(" "+_vm._s(product.product.name)+" ")]):_vm._e(),(_vm.msg.room.name == 'Bad')?_c('div',[_vm._v(" "+_vm._s(product.product.name)+" ")]):_vm._e()])],2)])}),0)]):_vm._e()])}),_c('div',{staticClass:"personalisiren"},[_c('div',[_c('p',[_vm._v(_vm._s(_vm.msg.room.name)+" personalisieren")])]),_c('div',{staticClass:"menu__personal"},_vm._l((_vm.configuratorData),function(p,i){return _c('div',{key:i,staticClass:"pointer"},[(
              (p.header == 'Fliesenarbeiten Waschtisch' &&
                _vm.msg.room.name != 'Wohnen') ||
              (p.header == 'Waschtischunterschrank' &&
                _vm.msg.room.name != 'Wohnen') ||
              (p.header == 'Waschtischanlage' && _vm.msg.room.name != 'Wohnen') ||
              (p.header == 'Malerarbeiten Wände' && _vm.msg.room.name != 'Bad') ||
              (p.header == 'Parkettarbeiten' && _vm.msg.room.name != 'Bad') ||
              (p.header == 'Türdrückergarnituren' && _vm.msg.room.name != 'Bad')
            )?_c('div',{on:{"click":function($event){_vm.visiblePersonal = p.header}}},[(p.header == 'Malerarbeiten Wände')?_c('p',{staticClass:"menu__personal--item"},[_vm._v(" Wandfarbe ")]):_vm._e(),(p.header == 'Parkettarbeiten')?_c('p',{staticClass:"menu__personal--item"},[_vm._v(" Parkett ")]):_vm._e(),(p.header == 'Türdrückergarnituren')?_c('p',{staticClass:"menu__personal--item"},[_vm._v(" Ausstattung ")]):_vm._e(),(p.header == 'Fliesenarbeiten Waschtisch')?_c('p',{staticClass:"menu__personal--item"},[_vm._v(" Fliesenfarben ")]):_vm._e(),(p.header == 'Waschtischunterschrank')?_c('p',{staticClass:"menu__personal--item"},[_vm._v(" Möbelfronten ")]):_vm._e(),(p.header == 'Waschtischanlage')?_c('p',{staticClass:"menu__personal--item"},[_vm._v(" Armaturen ")]):_vm._e()]):_vm._e()])}),0)])],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }