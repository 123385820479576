var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"products__info"},[(_vm.showSelect == false && _vm.msg.action == 0)?_c('div',[_c('h3',[_vm._v(" Wir bedanken uns für Ihre wundervolle Wahl! Ihre Anfrage wurde zu Zusammenfassung hinzugefügt ")])]):_vm._e(),(
      _vm.showSelect == true &&
      this.productGroup.portalOffer == false &&
      _vm.msg.action == 0
    )?_c('div',[_c('h3',[_vm._v("Angebotsportal geschlossen")])]):_vm._e(),(_vm.showSelect == true && this.productGroup.portalOffer == true)?_c('div',[(!_vm.loader)?_c('div',{staticClass:"loader-product"},[_c('img',{attrs:{"src":"http://garmonia.site/_garmonia/temp/crown/loader.gif","alt":""}})]):_vm._e(),_vm._l((_vm.arr),function(p,index){return _c('div',{key:index},[(index == _vm.productShow && _vm.loader)?_c('div',{staticClass:"product__info"},[_vm._l((p.product.images),function(img,index){return _c('div',{key:index,staticClass:"product__info--img"},[_c('img',{attrs:{"src":img.thumbUrl,"alt":""}})])}),_c('div',[_c('p',{staticClass:"product__name"},[_vm._v(_vm._s(p.product.name))])]),(!_vm.msg.onlySee)?_c('div',{staticClass:"products__info--button-group"},[(index > 0)?_c('button',{on:{"click":function($event){_vm.picked = _vm.arr[index - 1].product.id;
              _vm.prev();
              _vm.selectItemInfo();}}},[_vm._v(" ◄ ")]):_vm._e(),_c('button',{staticClass:"select",on:{"click":function($event){return _vm.select(
                _vm.msg.flatID.id,
                _vm.msg.room.name,
                _vm.msg.roomBook.id,
                _vm.msg.lineItems.id,
                _vm.msg.subLineItems.id,
                _vm.productGroup,
                p.id,
                p.product.id,
                p.product.name,
                p.priceInCents,
                _vm.msg.defaultQuantity.quantity
              )}}},[_vm._v(" Auswählen ")]),(index < _vm.arr.length - 1)?_c('button',{on:{"click":function($event){_vm.picked = _vm.arr[index + 1].product.id;
              _vm.next(_vm.arr.length - 1);
              _vm.selectItemInfo();}}},[_vm._v(" ► ")]):_vm._e()]):_vm._e()],2):_vm._e()])})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }