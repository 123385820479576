<template>
  <div class="anderungen">
    <div v-if="close" class="apartmentContainer">
      {{ close }}
      <div class="apartmentHead">
        <h3 class="headline">Änderungen bestätigen</h3>
        <button v-on:click="close = false">close</button>
      </div>
      <div class="apartmentUnsavedItems">
        <div class="apartmentUnsavedItemsList">
          <div>
            <span class="index">1</span> CEA - Innovo INV42, Two-hole mixer with
            swivelling spout
          </div>
          <div>
            <span class="index">2</span> CEA - Innovo INV42, Two-hole mixer with
            swivelling spout
          </div>
          <div class="last">
            <span class="index">3</span> CEA - Innovo INV42, Two-hole mixer with
            swivelling spout
            <div class="itemPreview">
              <img src="image/item-preview.jpg" />
              <img src="image/item-preview.jpg" />
            </div>
          </div>
        </div>
        <form
          id="apartmentUnsaved"
          class="form"
          method="post"
          action=""
          onsubmit=""
        >
          <div class="formInput formButton">
            <input type="button" value="Akzeptieren" />
            <a href="#" class="simpleButton">Ablehnen</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {

    }
  },
  props: {
    close: {}
  },
  name: 'Anderungen'
}
</script>

<style scope>
.apartmentContainer {
  width: 100%;
  max-width: 30em;
  box-sizing: border-box;
  padding: 1em;
  background-color: #fff;
  color: #323A41;
  position: relative;
}

.apartmentContainer .apartmentHead .headline {
  margin: 0;
  padding: 0;
  font-size: 1.25em;
}

.apartmentContainer .apartmentHead .apartmentClose {
  display: inline-block;
  position: absolute;
  top: 1em;
  right: 1em;
  width: 1em;
  height: 1em;
}

.apartmentContainer .apartmentHead .apartmentClose:before {
  content: " ";
  width: 1em;
  height: 1px;
  background-color: #323A41;
  position: absolute;
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 0.5em;
}

.apartmentContainer .apartmentHead .apartmentClose:after {
  content: " ";
  width: 1em;
  height: 1px;
  background-color: #323A41;
  position: absolute;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 0.5em;
}

.apartmentContainer .apartmentUnsavedItems {
  padding: 1em;
}

.apartmentContainer .apartmentUnsavedItems .index {
  display: inline-block;
  padding-right: 0.5em;
}

.apartmentContainer .apartmentUnsavedItems .apartmentUnsavedItemsList > div {
  padding: 1em 1.25em;
  border-bottom: 1px solid #bbb;
}

.apartmentContainer .apartmentUnsavedItems .apartmentUnsavedItemsList .last {
  border-bottom: 0;
  /*padding-bottom: 0;*/
}

.apartmentContainer
  .apartmentUnsavedItems
  .apartmentUnsavedItemsList
  .itemPreview
  img {
  margin-top: 1em;
  margin-right: 1em;
  display: inline-block;
}

.apartmentContainer .form .formInput {
  padding: 0;
  margin: 0;
}

.apartmentContainer .form .formInput input {
  margin: 0;
  width: 40%;
  background-color: #323A41;
  color: #fff;
}

.apartmentContainer .form .formInput a {
  color: #323A41;
  display: inline-block;
  margin-left: 3em;
  line-height: 2em;
}
</style>