<template>
  <div
    class="crown__rooms"
    :class="this.room.displayName == 'Zusammenfassung' ? 'scroll' : ''"
  >
    <div class="loader" v-if="startLoader">
      <div class="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>

    <div
      class="fixed-overlay-disclaimer fixed-overlay__modal-disclaimer disclaimer"
      v-if="disclaimer == true"
    >
      <div class="modal-disclaimer">
        <div class="modal_container-disclaimer">
          <h3>Hinweis</h3>
          <p>
            Pure können Sie gerne individuell anpassen in dem Sie auf weiße
            Punkte gehen
          </p>
          <button v-on:click="sendAkzeptieren(true)">Akzeptieren</button>
          <button v-on:click="sendAkzeptieren(false)">Ablehnen</button>
        </div>
      </div>
    </div>

    <div
      class="congigurator__img"
      v-if="room.displayName == 'Wohnen' && displayStyle == 'PURE_BASIC'"
    >
      <img :src="this.$store.getters.configurator.wohnen.src" alt="" />
    </div>
    <div
      class="congigurator__img"
      v-if="room.displayName == 'Bad' && displayStyle == 'PURE_BASIC'"
    >
      <img :src="this.$store.getters.configurator.bad.src" alt="" />
    </div>

    <img class="crown__rooms--background" :src="imgView" alt="background" />
    <!-- Menu -->
    <div
      class="menu"
      v-bind:class="'menu-' + this.room.displayName.toLowerCase()"
    >
      <router-link class="menu__logo" to="/">
        <img
          v-if="
            this.room.displayName.toLowerCase() === 'zusammenfassung' &&
            displayStyle != 'COCO'
          "
          src="./../assets/59_Logo_Bronze@2x.png"
          alt=""
        />
        <img v-else src="./../assets/59_Logo_Bronze@2x.png" alt="" />
      </router-link>

      <div
        v-if="displayStyle != 'PURE_BASIC'"
        class="crown__rooms--menu"
        v-bind:class="displayStyle == 'COCO' ? 'white-item' : ''"
      >
        <div v-for="(room, index) in rooms" :key="room">
          <button
            v-on:click="clickRoom(room, index, displayStyle)"
            v-bind:class="
              active[index] &&
              (displayStyle == 'PURE' ||
                displayStyle == 'SILK' ||
                displayStyle == 'COCO') &&
              arr[index] != 'Zusammenfassung'
                ? 'active'
                : active[index] &&
                  displayStyle == 'COCO' &&
                  arr[index] == 'Zusammenfassung'
                ? 'active-z'
                : active[index] &&
                  (displayStyle == 'PURE' ||
                    displayStyle == 'SILK' ||
                    displayStyle == 'COCO') &&
                  arr[index] == 'Zusammenfassung'
                ? 'active-coco-pure-z'
                : ''
            "
          >
            {{ arr[index] }}
          </button>
        </div>
        <button
          v-if="this.$store.getters.user.loggedIn"
          @click.prevent="signOut"
        >
          Abmelden
        </button>
      </div>
      <div
        v-else
        class="crown__rooms--menu"
        v-bind:class="displayStyle == 'COCO' ? 'white-item' : ''"
      >
        <div
          v-for="(room, index) in rooms"
          :key="room"
          :class="
            arr[index] == 'Wohnen' ||
            arr[index] == 'Bad' ||
            arr[index] == 'Grundriss' ||
            arr[index] == 'Zusammenfassung'
              ? arr[index]
              : 'none'
          "
        >
          <button
            v-if="
              arr[index] == 'Wohnen' ||
              arr[index] == 'Bad' ||
              arr[index] == 'Grundriss' ||
              arr[index] == 'Zusammenfassung'
            "
            v-on:click="clickRoom(room, index, displayStyle)"
            v-bind:class="
              active[index] &&
              displayStyle == 'PURE_BASIC' &&
              arr[index] != 'Zusammenfassung'
                ? 'active'
                : active[index] &&
                  displayStyle == 'COCO' &&
                  arr[index] == 'Zusammenfassung'
                ? 'active-z'
                : active[index] &&
                  displayStyle == 'PURE_BASIC' &&
                  arr[index] == 'Zusammenfassung'
                ? 'active-coco-pure-z'
                : ''
            "
          >
            {{ arr[index] }}
          </button>
          <button v-else>asdf</button>
        </div>

        <button
          v-if="this.$store.getters.user.loggedIn"
          @click.prevent="signOut"
        >
          Abmelden
        </button>
      </div>
    </div>
    <!-- MOODBOARD -->
    <div v-if="moodboard == true" class="crown__rooms--moonboard"></div>
    <!-- Modal Products -->
    <div v-if="productsModal" class="fixed-overlay fixed-overlay__modal">
      <div class="modal">
        <div class="modal_container">
          <div class="close">
            <button v-on:click="productsModal = false">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10.607"
                height="10.607"
                viewBox="0 0 10.607 10.607"
              >
                <g
                  id="Компонент_98_45"
                  data-name="Компонент 98 – 45"
                  transform="translate(0.354 0.354)"
                >
                  <line
                    id="Линия_1895"
                    data-name="Линия 1895"
                    x2="14"
                    transform="translate(0 0) rotate(45)"
                    fill="none"
                    stroke="#323a41"
                    stroke-width="1"
                  />
                  <line
                    id="Линия_1896"
                    data-name="Линия 1896"
                    y2="14"
                    transform="translate(9.9 0) rotate(45)"
                    fill="none"
                    stroke="#323a41"
                    stroke-width="1"
                  />
                </g>
              </svg>
            </button>
          </div>
          <div v-if="download">Download - {{ info }}</div>
          <products-info
            v-if="!download"
            :userLink="user"
            :msg="{
              flatID: { id: flatID },
              roomBook: { id: room.RoombookID },
              lineItems: { id: lineItems },
              subLineItems: { id: pointItemID },
              room: { name: room.displayName },
              defaultQuantity: { quantity: defaultQuantity },
              productGroup: productGroup,
              style: { action: displayStyle },
              action: action,
              onlySee: onlySee,
            }"
          />
        </div>
      </div>
    </div>
    <!-- Window check -->
    <div class="crown__room--check">
      <anderungen :close="windowCheck" />
    </div>
    <!-- View Window -->
    <div v-if="display">
      <div class="back__flats" v-if="this.$store.getters.user.loggedIn != true">
        <router-link :to="{ name: 'Flats' }"
          >&#9668; Zurück zur Wohnungsliste</router-link
        >
      </div>

      <div>
        <div class="img__view">
          <img :src="imgView" alt="" />
        </div>
      </div>

      <div class="zsfng" v-if="zusammenfassung">
        <zusammenfassung
          :roomBook="info"
          :setUser="{ key: setUser }"
          :msg="{
            roomName: { name: room.displayName },
            roomNumber: { number: number },
            flatID: { id: flatID },
            style: { displayStyle: displayStyle, imgPriceStyle: imgPriceStyle },
          }"
        />
      </div>

      <div class="button__start--img" v-if="aussicht">
        <div v-for="(v, index) in view" :key="index" id="bg">
          <button v-on:click="imgView = v.img">{{ v.imgNumber }}</button>
        </div>
      </div>
    </div>
    <!-- View Room -->
    <div v-if="!display">
      <div
        class="img__view"
        v-if="points == null"
        :class="numberImg != 0 ? 'up-img' : ''"
      >
        <img :src="imgView" alt="imgView" />
      </div>

      <svg
        v-if="points != null && room.displayName != 'Grundriss'"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        :viewBox="'0 0 ' + imgSizeWidth + ' ' + imgSizeHeight"
        :style="
          'enable-background: new 0 0 ' + imgSizeWidth + ' ' + imgSizeHeight
        "
        xml:space="preserve"
        class="img__view--svg"
      >
        <defs>
          <filter id="shadow">
            <feDropShadow
              dx="0"
              dy="0"
              stdDeviation="3"
              flood-color="#222222"
            />
          </filter>
        </defs>
        <g v-for="(point, item) in points.DotsList" :key="item + Math.random()">
          <circle
            v-on:click="
              product(
                points.DotsList[item].SubLineItemID,
                points.DotsList[item].LineItemID,
                points.DotsList[item].Action,
                points.DotsList[item].NavigationRoomID
              )
            "
            :class="'st0 ' + 'point' + item"
            :cx="points.DotsList[item].PositionX"
            :cy="imgSizeHeight - points.DotsList[item].PositionY"
            r="15"
            style="filter: url(#shadow)"
            stroke-width="1"
            stroke="#222222"
          />
        </g>
      </svg>

      <svg
        v-if="points != null && room.displayName == 'Grundriss'"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        :viewBox="'0 0 ' + imgSizeWidth + ' ' + imgSizeHeight"
        :style="
          'enable-background: new 0 0 ' + imgSizeWidth + ' ' + imgSizeHeight
        "
        xml:space="preserve"
        class="img__view--svg"
      >
        <defs>
          <filter id="shadow">
            <feDropShadow
              dx="0"
              dy="0"
              stdDeviation="3"
              flood-color="#222222"
            />
          </filter>
        </defs>
        <g v-for="(point, item) in points.DotsList" :key="item + Math.random()">
          <circle
            v-on:click="
              product(
                points.DotsList[item].SubLineItemID,
                points.DotsList[item].LineItemID,
                points.DotsList[item].Action,
                points.DotsList[item].NavigationRoomID
              )
            "
            :class="'st0 ' + 'point' + item"
            :cx="points.DotsList[item].PositionX"
            :cy="imgSizeHeight - points.DotsList[item].PositionY"
            r="36"
            style="filter: url(#shadow)"
            stroke-width="1"
            stroke="#222222"
          />
          <g
            id="Сгруппировать_5662"
            data-name="Сгруппировать 5662"
            :transform="`translate(${points.DotsList[item].PositionX - 89}, ${
              imgSizeHeight - points.DotsList[item].PositionY - 122
            })`"
          >
            <path
              id="Контур_7269"
              data-name="Контур 7269"
              d="M89.381,101.89A14.4,14.4,0,0,0,75,116.271c0,9.967,14.4,26.993,14.4,26.993s14.368-17.516,14.368-26.993A14.4,14.4,0,0,0,89.381,101.89Zm4.339,18.592a6.137,6.137,0,1,1,0-8.678,6.119,6.119,0,0,1,0,8.678Zm0,0"
              fill="none"
              stroke="#323a41"
              stroke-miterlimit="10"
              stroke-width="1"
            />
          </g>
        </g>
      </svg>

      <div
        class="img__view"
        v-if="points != null"
        :class="numberImg != 0 ? 'up-img' : ''"
      >
        <img :src="imgView" alt="imgView" />
      </div>

      <div class="back__flats" v-if="this.$store.getters.user.loggedIn != true">
        <router-link :to="{ name: 'Flats' }"
          >&#9668; Zurück zur Wohnungsliste</router-link
        >
      </div>

      <div v-if="displayStyle == 'SILK'" class="crown__rooms--block-img prev">
        <button
          class="prev"
          v-on:click="
            imgView = room.StylesData.SILK[numberImg - 1];
            styleRoom = 'SILK';
            numberImg = numberImg - 1;
            styleButton(roomPoints, styleRoom, numberImg);
          "
        >
          <img v-if="numberImg > 0" src="./../assets/prev.svg" alt="" />
        </button>
      </div>
      <div v-if="displayStyle == 'SILK'" class="crown__rooms--block-img next">
        <button
          v-if="this.room.displayName != 'Grundriss'"
          class="next"
          v-on:click="
            imgView = room.StylesData.SILK[numberImg + 1];
            styleRoom = 'SILK';
            numberImg = numberImg + 1;
            styleButton(roomPoints, styleRoom, numberImg);
          "
        >
          <img
            v-if="numberImg < room.StylesData.SILK.length - 1"
            src="./../assets/next.svg"
            alt=""
          />
        </button>
      </div>
      <div v-if="displayStyle == 'COCO'" class="crown__rooms--block-img prev">
        <button
          class="prev"
          v-on:click="
            imgView = room.StylesData.COCO[numberImg - 1];
            styleRoom = 'COCO';
            numberImg = numberImg - 1;
            styleButton(roomPoints, styleRoom, numberImg);
          "
        >
          <img v-if="numberImg > 0" src="./../assets/prev.svg" alt="" />
        </button>
      </div>
      <div v-if="displayStyle == 'COCO'" class="crown__rooms--block-img next">
        <button
          v-if="this.room.displayName != 'Grundriss'"
          class="next"
          v-on:click="
            imgView = room.StylesData.COCO[numberImg + 1];
            styleRoom = 'COCO';
            numberImg = numberImg + 1;
            styleButton(roomPoints, styleRoom, numberImg);
          "
        >
          <img
            v-if="numberImg < room.StylesData.COCO.length - 1"
            src="./../assets/next.svg"
            alt=""
          />
        </button>
      </div>
      <div v-if="displayStyle == 'PURE'" class="crown__rooms--block-img prev">
        <button
          class="prev"
          v-on:click="
            imgView = room.StylesData.PURE[numberImg - 1];
            styleRoom = 'PURE';
            numberImg = numberImg - 1;
            styleButton(roomPoints, styleRoom, numberImg);
          "
        >
          <img v-if="numberImg > 0" src="./../assets/prev.svg" alt="" />
        </button>
      </div>
      <div v-if="displayStyle == 'PURE'" class="crown__rooms--block-img next">
        <button
          v-if="this.room.displayName != 'Grundriss'"
          class="next"
          v-on:click="
            imgView = room.StylesData.PURE[numberImg + 1];
            styleRoom = 'PURE';
            numberImg = numberImg + 1;
            styleButton(roomPoints, styleRoom, numberImg);
          "
        >
          <img
            v-if="numberImg < room.StylesData.PURE.length - 1"
            src="./../assets/next.svg"
            alt=""
          />
        </button>
      </div>
      <!-- Bottom menu -->

      <div class="crown__rooms--block">
        <div class="crown__room--style">
          <div
            class="crown__rooms--room"
            :class="{
              coco: activeStyle == 'COCO',
              pure: activeStyle == 'PURE_BASIC' || activeStyle == 'PURE',
            }"
          >
            <div>WOHNUNG {{ number }}</div>
            <div class="crown__rooms--room-style" v-if="visibleStyle == true">
              Einrichtungsstil:
              <button>
                {{ styleName }}
              </button>
            </div>

            <div
              v-if="visibleStyle == true && room.displayName == 'Grundriss'"
              v-on:click="
                moodboard = true;
                visibleStyle = false;
                konfigurator = true;
              "
              class="footer-more"
            >
              <span v-if="room.displayName == 'Grundriss'"> Legende </span>
              <span v-if="visibleStyle">&#9650;</span>
              <span v-if="!visibleStyle">&#9660;</span>
            </div>

            <div
              v-if="visibleStyle == false"
              v-on:click="
                moodboard = true;
                visibleStyle = true;
                konfigurator = false;
              "
              class="footer-more"
            >
              Schließen
              <span v-if="visibleStyle">&#9650;</span>
              <span v-if="!visibleStyle">&#9660;</span>
            </div>
          </div>
        </div>

        <div
          v-if="visibleStyle == false && moodboard == true"
          class="crown__rooms--style-info"
          :class="
            displayStyle == 'COCO'
              ? 'coco'
              : displayStyle == 'PURE'
              ? 'pure'
              : displayStyle == 'PURE_BASIC'
              ? 'pure'
              : ''
          "
        >
        </div>

        <div
          v-if="visibleStyle == false && this.room.displayName == 'Grundriss'"
          class="crown__rooms--style-info"
          :class="
            displayStyle == 'COCO'
              ? 'coco'
              : displayStyle == 'PURE'
              ? 'pure'
              : ''
          "
        >
          <div v-if="displayStyle == 'SILK'">
            <img :src="this.room.StylesData.SILK[1]" alt="" width="60%" />
          </div>
          <div v-if="displayStyle == 'COCO'">
            <img :src="this.room.StylesData.COCO[1]" alt="" width="60%" />
          </div>
          <div v-if="displayStyle == 'PURE'">
            <img :src="this.room.StylesData.PURE[1]" alt="" width="60%" />
          </div>
          <div v-if="displayStyle == 'PURE_BASIC'">
            <img :src="this.room.StylesData.PURE[1]" alt="" width="60%" />
          </div>
        </div>

        <div
          v-if="visibleStyle == false && moodboard == false"
          class="crown__rooms--style-info"
          :class="
            displayStyle == 'COCO'
              ? 'coco'
              : displayStyle == 'PURE_BASIC' || displayStyle == 'PURE'
              ? 'pure'
              : ''
          "
        >
          <moodboard
            :msg="{
              room: { name: room.displayName },
              info: configuratorData,
              flat: flatID,
              start: startConfigurator,
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase"
import ProductsInfo from './../components/ProductsInfo.vue'
import Anderungen from './../components/Anderungen.vue'
import Zusammenfassung from './../components/Zusammenfassung';
import Moodboard from './../components/Moodboard';
import axios from 'axios'
import moment from 'moment'
import BAD_DATA from './../bd/Bad.json'
import WOHNEN_DATA from './../bd/Wohnen.json'
import bd from './../bd/9954.json'
import { mapGetters } from "vuex";

export default {
  components: { ProductsInfo, Anderungen, Zusammenfassung, Moodboard },
  data: function () {
    return {
      styleName: '',
      startLoader: true,
      href: '',
      setUser: '',
      points: {},
      active: [],
      activeStyle: '',
      productsModal: false,
      download: false,
      windowCheck: false,
      visibleStyle: true,
      showVisibleStyle: true,
      moodboard: true,
      styleFlat: [],
      room: {
        displayName: '',
        StylesData: {
          COCO: {},
          PURE: {},
          SILK: {}
        },
        startImg: '',
        RoombookID: ''
      },
      display: false,
      displayStyle: 'SILK',
      imgView: '',
      view: [],
      arr: [],
      temp: {},
      arr_rooms: [],
      roomPoints: '',
      styleRoom: 'SILK',
      numberImg: 0,
      imgSizeWidth: 3500,
      imgSizeHeight: 1969,
      info: {},
      pointItemID: '',
      defaultQuantity: '',
      lineItems: '',
      title: '',
      subLineItems: [],
      productGroup: {},
      aussicht: false,
      zusammenfassung: false,
      onlySee: false,
      imgPriceStyle: [],
      konfigurator: false,
      bad: [],
      wohnen: [],
      configuratorData: [],
      startConfigurator: '',
      rooms: {},
      styleFlatDescription: {},
      disclaimer: false,
      addKontaktLink: [],
      availableStyle: [{'SILK': true}],
      action: {},
      activeStart: {},
      styleStart: {},
    };
  },
  props: {
    flat: {},
    number: {},
    flatID: {},
    // user: {},
    roomStart: {},
    framedMeters: {},
    cost: {}
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  methods: {
    signOut() {
      alert('Auf Wiedersehen')

      localStorage.removeItem("crown");
      localStorage.removeItem("crownBayerId");
      axios.get('https://us-central1-cadman-63dc7.cloudfunctions.net/getAnonUser')
        .then((result) => {
          this.crownKey = result.data
        })
        .finally(() => {
          localStorage.setItem('crown', this.crownKey);
        });
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.push('/').catch(() => { });
        });
    },
    windowCheckAlert() {
      this.windowCheck = true
    },
    clickRoom(room, index, style) {

      this.konfigurator = false
      this.moodboard = true
      this.visibleStyle = true

      localStorage.setItem('crownRoom', room)
      localStorage.setItem('crownRoomStartActive', index)
      localStorage.setItem('crownRoomStyle', style)

      this.startLoader = true

      this.imgView = 'http://garmonia.site/_garmonia/temp/crown/white.jpg'
      this.displayStyle = style
      this.activeStyle = style;
      this.roomPoints = room
      this.styleRoom = style
      this.numberImg = 0

      for (let i = 0; i < this.rooms.length; i++) {
        if (i == index) {
          this.active[i] = true
        } else
          this.active[i] = false
      }

      axios.get('https://cadman-63dc7.firebaseio.com/Rooms/' + room + '/.json')
        .then((response) => {
          this.room = {
            displayName: '',
            StylesData: {
              COCO: ['http://garmonia.site/_garmonia/temp/crown/no-photo.jpg'],
              PURE: ['http://garmonia.site/_garmonia/temp/crown/no-photo.jpg'],
              SILK: ['http://garmonia.site/_garmonia/temp/crown/no-photo.jpg'],
              NONE: ['http://garmonia.site/_garmonia/temp/crown/no-photo.jpg'],
              PURE_BASIC: ['http://garmonia.site/_garmonia/temp/crown/no-photo.jpg']
            },
            RoombookID: '',
            menuItem: index
          }

          this.room.displayName = response.data.Data.displayName
          this.room.StylesData.COCO = response.data.Data.StylesData.COCO
          this.room.StylesData.PURE = response.data.Data.StylesData.PURE
          this.room.StylesData.SILK = response.data.Data.StylesData.SILK
          this.room.StylesData.NONE = response.data.Data.StylesData.NONE
          this.room.StylesData.PURE_BASIC = response.data.Data.StylesData.PURE_BASIC

          this.room.active = true
        })
        .finally(() => {

          if (this.room.StylesData.COCO == undefined) {
            this.room.StylesData.COCO = ['http://garmonia.site/_garmonia/temp/crown/no-photo.jpg']
          }
          if (this.room.StylesData.PURE == undefined) {
            this.room.StylesData.PURE = ['http://garmonia.site/_garmonia/temp/crown/no-photo.jpg']
          }
          if (this.room.StylesData.SILK == undefined) {
            this.room.StylesData.SILK = ['http://garmonia.site/_garmonia/temp/crown/no-photo.jpg']
          }
          if (this.room.StylesData.PURE_BASIC == undefined) {
            this.room.StylesData.PURE_BASIC = ['http://garmonia.site/_garmonia/temp/crown/no-photo.jpg']
          }
          if (this.room.StylesData.NONE == undefined) {
            this.room.StylesData.NONE = ['http://garmonia.site/_garmonia/temp/crown/no-photo.jpg']
          }

          if (style == 'SILK' && this.room.displayName != 'Aussicht')
            this.imgView = this.room.StylesData.SILK[0]
          if (style == 'COCO' && this.room.displayName != 'Aussicht')
            this.imgView = this.room.StylesData.COCO[0]
          if (style == 'PURE' && this.room.displayName != 'Aussicht')
            this.imgView = this.room.StylesData.PURE[0]
          if (style == 'PURE_BASIC' && this.room.displayName != 'Aussicht')
            this.imgView = this.room.StylesData.PURE_BASIC[0]

          if (this.room.displayName == 'Aussicht') {
          this.imgView = this.room.StylesData.SILK[0]
          }

          if (this.room.displayName == 'Zusammenfassung') {
            this.imgPriceStyle = []
            if (this.displayStyle == 'COCO') {
              this.imgView = this.room.StylesData.COCO[0]
              for (let i = 1; i < this.room.StylesData.COCO.length; i++) {
                this.imgPriceStyle.push(this.room.StylesData.COCO[i])
              }
            }
            if (this.displayStyle == 'SILK') {
              this.imgView = this.room.StylesData.SILK[0]
              for (let i = 1; i < this.room.StylesData.SILK.length; i++) {
                this.imgPriceStyle.push(this.room.StylesData.SILK[i])
              }
            }
            if (this.displayStyle == 'PURE') {
              this.imgView = this.room.StylesData.PURE[0]
              for (let i = 1; i < this.room.StylesData.PURE.length; i++) {
                this.imgPriceStyle.push(this.room.StylesData.PURE[i])
              }
            }
            if (this.displayStyle == 'PURE_BASIC') {
              this.imgView = this.room.StylesData.PURE_BASIC[0]
              for (let i = 1; i < this.room.StylesData.PURE_BASIC.length; i++) {
                this.imgPriceStyle.push(this.room.StylesData.PURE_BASIC[i])
              }
            }
            this.display = true
            this.aussicht = false
            this.zusammenfassung = true
          }
          this.startLoader = false
          this.addPoints(room, style, this.numberImg)
        })

      this.display = false
      this.roomPoints = room

      this.lineItems = ''
      this.pointItemID = ''
      this.productGroup = {}
    },
    styleButton(room, style, img) {
      localStorage.setItem('crownRoom', room)
      this.availableStyle.includes(style)
          ? localStorage.setItem('crownRoomStyle', style)
          : localStorage.setItem('crownRoomStyle', this.availableStyle[0]);
      this.addPoints(room, style, img)
      if (localStorage.getItem('crownRoomStyle') == 'PURE_BASIC') {
        this.$router.push({ name: 'Flat', params: { styleStart: 'PURE' } })

        let urlAnonim = 'https://us-central1-cadman-63dc7.cloudfunctions.net/getPureDisclimer?localStorage=' + localStorage.getItem('crown')
        let urlUser = 'https://us-central1-cadman-63dc7.cloudfunctions.net/getAuthPureDisclimer?localStorage=' + localStorage.getItem('crown')
        var q = null
        this.disclaimer = false
        this.startLoader = true

        if (this.user.loggedIn == false) {
          axios.get(urlAnonim)
            .then((response) => {
              q = response.data
            })
            .finally(() => {
              if (q == true) {
                this.disclaimer = false
                this.startLoader = false
              }
              if (q == false) {
                this.disclaimer = true
                this.startLoader = false
              }
            })
        }

        if (this.user.loggedIn == true) {
          axios.get(urlUser)
            .then((response) => {
              q = response.data
            })
            .finally(() => {
              if (q == true) {
                this.disclaimer = false
              }
              if (q == false) {
                this.disclaimer = true
              }
              this.startLoader = false
            })
        }
      }
      else {
        this.$router.push({ name: 'Flat', params: { styleStart: localStorage.getItem('crownRoomStyle') } })
      }
    },
    sendAkzeptieren(action) {
      if (action) {
        if (this.$store.getters.user.loggedIn == false) {
          let url = 'https://us-central1-cadman-63dc7.cloudfunctions.net/setPureDisclimer?localStorage=' + localStorage.getItem('crown') + '&date=' + moment().unix()
          axios.get(url)
            .then((response) => {
              console.log(response)
            })
            .finally(() => {
              this.disclaimer = false
            })
        }
        if (this.$store.getters.user.loggedIn == true) {
          let urlUser = 'https://us-central1-cadman-63dc7.cloudfunctions.net/setAuthPureDisclimer?localStorage=' + localStorage.getItem('crown') + '&date=' + moment().unix()
          axios.get(urlUser)
            .then((response) => {
              console.log(response)
            })
            .finally(() => {
              this.disclaimer = false
            })
        }
      }
      if (!action) {
        this.$router.push('/').catch(() => { });
      }
    },
    getAllPoints(points) {
      this.$store.dispatch("EMPTY_PRODUCT");

      let allProducts = {}
      allProducts = this.info

      if (allProducts.data != undefined) {
        for (let key in points) {
          for (let i = 0; i < allProducts.data.roomBook.lineItems.length; i++) {
            if (allProducts.data.roomBook.lineItems[i].id == points[key].LineItemID) {
              let arr = allProducts.data.roomBook.lineItems[i].subLineItems
              for (let j = 0; j < arr.length; j++)
                if (arr[j].id == points[key].SubLineItemID) {
                  let arrProduct = arr[j].productGroup.productPrices
                  for (let k = 0; k < arrProduct.length; k++)
                    if (k == 0)
                      this.$store.dispatch("ADD_PRODUCT", { produdctID: arr[j].productGroup.id, id: arrProduct[k].product.id, product: arrProduct[k].product.name, action: true });
                    else
                      this.$store.dispatch("ADD_PRODUCT", { produdctID: arr[j].productGroup.id, id: arrProduct[k].product.id, product: arrProduct[k].product.name, action: false });
                }
            }
          }
        }
      }
    },
    product(sub, p, action, room) {
      if (action == 1) {
        for (var key_room in this.rooms) {
          if (this.rooms[key_room] == room) {
            this.clickRoom(room, key_room, this.displayStyle)
          }
        }
      }
      if (action == 2) {
        this.lineItems = p
        this.pointItemID = sub
        this.productsModal = true
        this.download = true
        this.productGroup = {}

        var info

        if (this.flatID == '9954') {
          info = this.info
        }
        if (this.flatID != '9954') {
          info = this.info.data
        }

        for (let i = 0; i < info.roomBook.lineItems.length; i++) {
          if (info.roomBook.lineItems[i].id == p) {
            let arr = info.roomBook.lineItems[i].subLineItems
            for (let j = 0; j < arr.length; j++)
              if (arr[j].id == sub) {
                this.productGroup = arr[j].productGroup
                this.defaultQuantity = arr[j].defaultQuantity
              }
          }
        }
        this.download = false
      }

      if (action == 4) {
        this.startConfigurator = p
        if (this.room.displayName == 'Bad') {
          this.configuratorData = this.bad.data.roomBook
          this.moodboard = false;
          this.visibleStyle = false;
        }
        if (this.room.displayName == 'Wohnen') {
          this.configuratorData = this.wohnen.data.roomBook
          this.moodboard = false;
          this.visibleStyle = false;
        }
      }

    },
    addPoints(room, style, img) {
      this.imgSizeWidth = 3500
      this.imgSizeHeight = 1969
      this.points = {}
      axios.get('https://cadman-63dc7.firebaseio.com/ImageData/' + room + '-' + style + '-' + img + '/.json')
        .then((response) => {
          this.points = response.data
        })
        .finally(() => {
          if (this.points != null) {
            this.getAllPoints(this.points.DotsList)
          }


          var imgS = new Image();
          imgS.src = null
          imgS.onload = () => {
            this.imgSizeWidth = imgS.width
            this.imgSizeHeight = imgS.height
          };
          imgS.src = this.imgView;
        })
    }
  },
  mounted() {
    if (!this.user.loggedIn) {
      this.$store.state.configurator = {
        bad: {
          src: 'https://firebasestorage.googleapis.com/v0/b/cadman-63dc7.appspot.com/o/Images%2F1a48aacf-870e-4357-bb09-e31eaf8001f1.jpg?alt=media&token=f9655f2b-8743-47c7-9d45-5b7ef01ccd8d',
          select: []
        },
        wohnen: {
          src: 'https://firebasestorage.googleapis.com/v0/b/cadman-63dc7.appspot.com/o/Images%2F8f4dbab0-8a1b-49c9-880a-a46c0dd3ab4f.jpg?alt=media&token=295aa2bc-2c32-4ed2-bf78-f24819a47ca8',
          select: []
        }
      }
      this.$store.state.selectConfigurator = {
            bad: [{
                    subLineItemsName: "Bauseitige Möbel",
                    productID: "77958"
                },
                {
                    subLineItemsName: "Waschtischarmatur",
                    productID: "75002"
                },
                {
                    subLineItemsName: "Waschtisch mit Becken",
                    productID: "77902"
                }
            ],
            wohnen: [{
                    subLineItemsName: "Wandfarbe",
                    productID: "77875"
                },
                {
                    subLineItemsName: "Parkett",
                    productID: "77872"
                },
                {
                    subLineItemsName: "Türbeschlag",
                    productID: "77882"
                }
            ]
        }
    }

    if (this.user.loggedIn) {
      let configArr
      axios.get('https://cadman-63dc7.firebaseio.com/Users/' + localStorage.getItem('crown') + '/UnitId/' + localStorage.getItem('crownBayerFlatId') + '/.json')
        .then((response) => {
          configArr = response.data
        })
        .finally(() => {
          let i = configArr.length - 1
          this.$store.state.configurator = JSON.parse(configArr[i].configurator)
        })
    }


    let roomStartActive = 0
    this.imgView = 'http://garmonia.site/_garmonia/temp/crown/white.jpg'
    this.$store.dispatch("ADD_MOODBOARD", { picked: 'flatID', value: this.flatID });
    this.$store.dispatch("ADD_FRAMEDMETERS", this.framedMeters)
    this.$store.dispatch("ADD_COST", this.cost)

    axios.get('https://cadman-63dc7.firebaseio.com/Flats/' + this.flat + '/.json')
      .then((response) => {
        this.rooms = response.data.Data.SubEntitiesIds;
        if(response.data.Data.AvailableStyle){
          let obj =  response.data.Data.AvailableStyle;
          let keys = Object.keys(obj);
          this.availableStyle = keys.filter(function(key) {
            return obj[key]
          });
        }
        this.styleStart = this.availableStyle.includes(this.styleStart) ?
            this.styleStart : this.availableStyle[0];
      })

    axios.get('https://cadman-63dc7.firebaseio.com/Rooms/.json')
      .then((response) => {
        this.arr_rooms = response.data
      })
      .finally(() => {

        this.temp = Object.entries(this.arr_rooms)
        for (let i = 0; i < this.rooms.length; i++) {
          for (let j = 0; j < this.temp.length; j++) {
            if (this.rooms[i] == this.temp[j][0]) {
              if(this.temp[j][1].Data.displayName === "Grundriss"){
                this.styleName = this.temp[j][1].Data.Localization.EN;
              }              if (i == 0) {
                this.imgView = this.temp[j][1].Data.StylesData.SILK
              }
              this.arr.push(this.temp[j][1].Data.displayName)
            }
          }
        }

        let value = { room: this.roomStart, active: roomStartActive, style: 'SILK' }
        this.$store.dispatch("ADD_SAVE", value)

        if (this.flatID == '9954') {
          this.info = bd.data
          this.room.RoombookID = bd.data.roomBook.id
        }
        else {
          axios.get('https://us-central1-cadman-63dc7.cloudfunctions.net/getRoomData?unitid=' + this.flatID)
            .then((result) => {
              this.info = result.data
            })
            .finally(() => {
              this.info = this.info.data
              this.room.RoombookID = this.info.roomBook.id
            })
        }

        //first in view
        if (this.action == 'view') {
          this.setUser = this.user
          this.onlySee = true
          this.action = 0
          this.activeStart = this.arr.length - 1
          this.styleButton(this.rooms[this.activeStart], this.styleStart, 0)
          this.clickRoom(this.rooms[this.activeStart], this.activeStart, this.styleStart)
        }
        //first in design
        if (this.action == 'design' && this.roomStart == 'Wohnen' && this.styleStart == 'SILK') {
          this.setUser = localStorage.getItem('crown');
          this.action = 0
          this.onlySee = false

          for (let key in this.arr) {
            if (this.arr[key] == this.roomStart) {
              this.activeStart = key
            }
          }

          localStorage.setItem('crownAction', this.action)
          localStorage.setItem('crownRoom', this.rooms[this.activeStart])
          localStorage.setItem('crownRoomStartActive', this.activeStart)
          localStorage.setItem('crownRoomStyle', this.styleStart)
          localStorage.setItem('crownBayerFlatId', this.flatID)

          this.styleButton(this.rooms[this.activeStart], this.styleStart, 0)
          this.clickRoom(this.rooms[this.activeStart], this.activeStart, this.styleStart)
        }
        if (this.action == 'design' && this.roomStart == 'Grundriss' && this.styleStart == 'SILK') {
          this.setUser = localStorage.getItem('crown');
          this.action = 0
          this.onlySee = false

          for (let key in this.arr) {
            if (this.arr[key] == this.roomStart) {
              this.activeStart = key
            }
          }

          localStorage.setItem('crownAction', this.action)
          localStorage.setItem('crownRoom', this.rooms[this.activeStart])
          localStorage.setItem('crownRoomStartActive', this.activeStart)
          localStorage.setItem('crownRoomStyle', this.styleStart)
          localStorage.setItem('crownBayerFlatId', this.flatID)

          this.styleButton(this.rooms[this.activeStart], this.styleStart, 0)
          this.clickRoom(this.rooms[this.activeStart], this.activeStart, this.styleStart)
        }
        //reload
        if (this.action == 'design') {
          this.action = 0
          this.onlySee = false
          for (let key in this.arr) {
            if (this.arr[key] == this.roomStart) {
              this.activeStart = key
            }
          }
          this.action = localStorage.getItem('crownAction')
          this.activeStart = localStorage.getItem('crownRoomStartActive')
          this.styleStart =
              this.availableStyle.includes(localStorage.getItem('crownRoomStyle')) ?
                  localStorage.getItem('crownRoomStyle') : this.availableStyle[0];
          let room = localStorage.getItem('crownRoom')

          this.styleButton(room, this.styleStart, 0)
          this.clickRoom(room, this.activeStart, this.styleStart)
        }

        this.startLoader = false

      })

    this.bad = BAD_DATA
    this.wohnen = WOHNEN_DATA

    let arr

    axios.get('https://cadman-63dc7.firebaseio.com/Styles/.json')
      .then((response) => {
        this.styleFlatDescription = response.data;
      })
      .finally(() => {
        arr = this.styleFlatDescription['PURE_BASIC'].Description.split('über den Kontaktformular zu')
        this.addKontaktLink[0] = arr[0]
        this.addKontaktLink[1] = arr[1]

        this.styleFlat.push(
          { header: this.styleFlatDescription['SILK'].Name, description: this.styleFlatDescription['SILK'].Description },
          { header: this.styleFlatDescription['COCO'].Name, description: this.styleFlatDescription['COCO'].Description },
          { header: this.styleFlatDescription['PURE'].Name, description: this.styleFlatDescription['PURE'].Description },
          { header: this.styleFlatDescription['PURE_BASIC'].Name, description: this.styleFlatDescription['PURE_BASIC'].Description }
        )
      })

    this.moodboard = true;
    this.visibleStyle = true;
  }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Tenor+Sans&display=swap");

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
}
.loader img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.crown__rooms--background {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -100;
  filter: blur(10px);
}
button {
  padding: 0;
  margin: 1em;
  border: 0px solid #fff;
  background-color: rgba(255, 255, 255, 0);
}
.back__flats {
  position: fixed;
  top: 0;
  padding-left: 1em;
  width: 100%;
  padding-top: 1em;
  z-index: 2;
}
.back__flats a {
  color: #fff;
}
.back__flats a:hover {
  color: #ad8059;
}
.back__flats button {
  color: #fff;
  padding: 0;
}
.back__flats button:hover {
  color: #ad8059;
}
.crown__rooms h1 {
  width: 100%;
  text-align: center;
}
.crown__rooms h3 {
  width: 100%;
  text-align: center;
}
.background__room {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #ad8059;
  z-index: -1;
}
.img__view {
  position: fixed;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.img__view--svg {
  height: 100vh;
  z-index: 1;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.img__view img {
  height: 100vh;
}
.img__view svg {
  height: 100vh;
}
.crown__rooms--menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* margin-top: 1em; */
  width: 1178px;
  margin: 2em auto;
}
.crown__rooms--menu > div {
  text-align: center;
}
.crown__rooms--menu button {
  font-family: "35-FTR", sans-serif;
  font-size: 15px;
  letter-spacing: 0.06rem;
  text-decoration: none;
  color: #fff;
  padding-bottom: 0.5em;
  margin: 0;
  cursor: pointer;
  -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.7));
}
.crown__rooms--menu button.active {
  border-bottom: 1px solid #fff;
}
.crown__rooms--menu button.active-z {
  border-bottom: 1px solid #fff;
}
.crown__rooms--menu button.active-coco-pure-z {
  border-bottom: 1px solid #323a41;
}
.crown__rooms--menu button:focus {
  outline: none;
  border-bottom: 1px solid #fff;
}
.button__start--img {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: fixed;
  bottom: 2em;
  width: 100%;
}
.button__start--img > div {
  text-align: center;
  padding: 0.5em;
}
.button__start--img button {
  padding: 1em;
}
.crown__rooms--back {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.menu__header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 2rem;
  margin-left: 12rem;
  margin-right: 12rem;
}
.back__flats button {
  margin: 0;
}
.crown__room--style {
  display: flex;
  justify-content: center;
  bottom: 2em;
  margin: 0 auto;
  width: 100%;
}
.crown__room--style .crown__rooms--room {
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  bottom: 2em;
  width: 1178px;
  justify-content: space-between;
  font-family: "35-FTR", sans-serif;
  font-size: 15px;
  letter-spacing: 0.06rem;
  box-sizing: border-box;
  height: 35px;
  padding-left: 2em;
  padding-right: 2em;
  align-items: center;
  justify-content: space-between;
}
.crown__room--style .crown__rooms--room button:after {
    content: " / ";
  margin-right: 5px;
  }
.crown__room--style .crown__rooms--room button:last-child:after {
  content: "";
  margin-right: 0;
}

  .coco {
    background-color: #323a41 !important;
    color: #fff !important;
  }
  .coco button {
    color: #fff;
  }
  .coco button.style-border {
    border-bottom: 1px solid #fff;
  }
  .coco button:focus.style-border {
    border-bottom: 1px solid #fff;
  }
  .pure {
    background-color: #b4afae !important;
    color: #fff;
  }
  .pure button {
    color: #fff;
  }
  .pure button.style-border {
    border-bottom: 1px solid #fff;
  }
  .pure button:focus.style-border {
    border-bottom: 1px solid #fff;
  }
  .crown__rooms--room-style button {
    margin: 0;
    margin: 0em 0em 0em 0em;
    text-shadow: 0px 0px;
    cursor: pointer;
  }
  .crown__rooms--room-style button:focus {
    outline: none;
    border-bottom: 1px solid #323a41;
  }
  .style-border {
    border-bottom: 1px solid #323a41;
  }
  .crown__rooms--block-img .next {
    position: fixed;
    z-index: 100;
    top: 50vh;
    right: 0;
    outline: none;
  }
  .crown__rooms--block-img .prev {
    position: fixed;
    z-index: 100;
    top: 50vh;
    left: 0;
    outline: none;
  }
  .crown__rooms--block-img .silk,
  .crown__rooms--block-img .coco,
  .crown__rooms--block-img .pure {
    display: flex;
  }
  .crown__rooms--block-img .silk button,
  .crown__rooms--block-img .coco button,
  .crown__rooms--block-img .pure button {
    color: #fff;
  }
  .crown__rooms--block-img .silk button:focus,
  .crown__rooms--block-img .coco button:focus,
  .crown__rooms--block-img .pure button:focus {
    outline: none;
  }
  .crown__rooms--block {
    position: fixed;
    width: 100%;
    bottom: 35px;
    z-index: 4;
  }
  .crown__rooms--block.zusammenfassung-info {
    width: 410px;
    left: calc(50% - 205px);
    bottom: 35px;
  }
  svg g circle {
    fill: #fff;
  }
  svg g:hover circle {
    fill: #ad8059;
    cursor: pointer;
  }
  .button__start--img button {
    padding: 0;
    padding-bottom: 0.5em;
    color: #fff;
    outline: none;
  }
  .button__start--img button:focus {
    color: #fff;
    outline: none;
    border-bottom: 1px solid #fff;
  }
  .fixed-overlay {
    z-index: 200;
    position: fixed;
    overflow: auto;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .fixed-overlay__modal {
    text-align: center;
    white-space: nowrap;
  }
  .fixed-overlay__modal::after {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 100%;
    content: "";
  }
  .modal {
    display: inline-block;
    vertical-align: middle;
  }
  .modal_container {
    margin: 50px;
    padding: 20px;
    text-align: left;
    white-space: normal;
    background-color: #fff;
    color: #323a41;
    width: 400px;
    height: 400px;
  }
  .modal_container .close {
    position: relative;
  }
  .modal_container button {
    position: absolute;
    right: 0;
    margin: 0;
  }
  .menu {
    position: relative;
    width: 100%;
    z-index: 2;
  }
  .crown__room--check {
    top: 30%;
    left: 10%;
    position: absolute;
  }
  .next img,
  .prev img {
    cursor: pointer;
    outline: none;
    -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.7));
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.7));
  }
  .crown__rooms--style-info {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background-color: #ccc;
    display: flex;
    width: 1178px;
    font-family: "35-FTR", sans-serif;
    font-size: 0.8rem;
    letter-spacing: 0.06rem;
    margin: 0 auto;
    background-color: rgba(238, 237, 236, 0.6);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    box-sizing: border-box;
  }
  .crown__rooms--style-info h1 {
    /*font-family: 'Tenor Sans', sans-serif;*/
    margin-top: 0.2em;
    margin-bottom: 0.3em;
    font-size: 60px;
    font-family: "Magnat Head";
    font-weight: 300;
    font-style: normal;
    letter-spacing: 0.3em;
  }
  .crown__rooms--style-info p {
    padding: 3em;
    padding-top: 0;
    font-size: 1em;
    max-width: 50%;
    margin: 0 auto;
  }
  .crown__rooms--style-info.coco p,
  .crown__rooms--style-info.pure p {
    color: #fff;
  }
  .crown__rooms--style-info.coco {
    background-color: rgba(50, 58, 65, 0.6) !important;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
  }
  .crown__rooms--style-info.pure {
    background-color: rgba(180, 175, 174, 0.6) !important;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
  }
  .crown__rooms--block-moodboard {
    width: 80%;
    margin: 0 auto;
    text-align: center;
  }
  .crown__rooms--block-moodboard button {
    margin-top: 0;
    color: #fff;
  }

  /*
  .crown__rooms--moonboard {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .crown__rooms--moonboard .close {
    position: relative;
    width: 50%;
    text-align: right;
    top: calc(50% - 24%);
    left: calc(50% - 26%);
    z-index: 100;
  }
  .crown__rooms--moonboard .close button {
    margin: 0;
  }
  */
  .footer-more {
    cursor: pointer;
  }
  .white-item {
    color: white;
  }
  .congigurator__img {
    position: fixed;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
  }
  .congigurator__img img {
    height: 100vh;
  }

  .moodboard__close {
    height: 100vh;
    width: 100vw;
    background: rgba(255, 255, 255, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
  }

  .up-img {
    z-index: 1;
  }

  .header-konfigurator {
    font-size: 24px !important;
    padding-top: 1em !important;
    padding-bottom: 1em !important;
    letter-spacing: 0.2em !important;
  }

  .lets-go {
    background-color: #c5bfbf;
    padding: 0;
    margin: 0;
    margin-bottom: 2em;
    padding: 1em;
    border-radius: 16px;
    font-size: 16px;
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 0.8em;
    box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
  }

  .scroll {
    height: 100vh;
    overflow-y: scroll;
  }

  .scroll .back__flats {
    position: relative;
    /*width: 250px;*/
    top: -179px;
    margin-left: 1em;
  }

  .fixed-overlay__modal-disclaimer {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    line-height: 1;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(10px);
    text-align: center;
    white-space: nowrap;
  }

  .fixed-overlay__modal-disclaimer::after {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 100%;
    content: "";
    backdrop-filter: blur(10px);
    opacity: 0.5;
  }

  .modal-disclaimer {
    display: inline-block;
    vertical-align: middle;
  }

  .modal_container-disclaimer {
    margin: 6em;
    padding: 2em;
    padding-left: 4em;
    padding-right: 4em;
    min-width: 200px;
    text-align: left;
    white-space: normal;
    background-color: #fff;
    color: #323a41;
    text-align: center;
  }

  .modal_container-disclaimer h3 {
    font-size: 24px;
  }

  .modal_container-disclaimer button {
    border: none;
    font: inherit;
    color: inherit;
    background-color: #d6cec1;
    padding: 1em;
    margin: 0;
    margin-right: 1em;
    margin-top: 2em;
    margin-bottom: 2em;
    text-shadow: 0px 0px 0px #323a41;
  }

.add__kontakt--link {
  text-decoration: underline;
  font-size: 1em;
}
</style>
