var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"crown__rooms",class:this.room.displayName == 'Zusammenfassung' ? 'scroll' : ''},[(_vm.startLoader)?_c('div',{staticClass:"loader"},[_vm._m(0)]):_vm._e(),(_vm.disclaimer == true)?_c('div',{staticClass:"fixed-overlay-disclaimer fixed-overlay__modal-disclaimer disclaimer"},[_c('div',{staticClass:"modal-disclaimer"},[_c('div',{staticClass:"modal_container-disclaimer"},[_c('h3',[_vm._v("Hinweis")]),_c('p',[_vm._v(" Pure können Sie gerne individuell anpassen in dem Sie auf weiße Punkte gehen ")]),_c('button',{on:{"click":function($event){return _vm.sendAkzeptieren(true)}}},[_vm._v("Akzeptieren")]),_c('button',{on:{"click":function($event){return _vm.sendAkzeptieren(false)}}},[_vm._v("Ablehnen")])])])]):_vm._e(),(_vm.room.displayName == 'Wohnen' && _vm.displayStyle == 'PURE_BASIC')?_c('div',{staticClass:"congigurator__img"},[_c('img',{attrs:{"src":this.$store.getters.configurator.wohnen.src,"alt":""}})]):_vm._e(),(_vm.room.displayName == 'Bad' && _vm.displayStyle == 'PURE_BASIC')?_c('div',{staticClass:"congigurator__img"},[_c('img',{attrs:{"src":this.$store.getters.configurator.bad.src,"alt":""}})]):_vm._e(),_c('img',{staticClass:"crown__rooms--background",attrs:{"src":_vm.imgView,"alt":"background"}}),_c('div',{staticClass:"menu",class:'menu-' + this.room.displayName.toLowerCase()},[_c('router-link',{staticClass:"menu__logo",attrs:{"to":"/"}},[(
          this.room.displayName.toLowerCase() === 'zusammenfassung' &&
          _vm.displayStyle != 'COCO'
        )?_c('img',{attrs:{"src":require("./../assets/59_Logo_Bronze@2x.png"),"alt":""}}):_c('img',{attrs:{"src":require("./../assets/59_Logo_Bronze@2x.png"),"alt":""}})]),(_vm.displayStyle != 'PURE_BASIC')?_c('div',{staticClass:"crown__rooms--menu",class:_vm.displayStyle == 'COCO' ? 'white-item' : ''},[_vm._l((_vm.rooms),function(room,index){return _c('div',{key:room},[_c('button',{class:_vm.active[index] &&
            (_vm.displayStyle == 'PURE' ||
              _vm.displayStyle == 'SILK' ||
              _vm.displayStyle == 'COCO') &&
            _vm.arr[index] != 'Zusammenfassung'
              ? 'active'
              : _vm.active[index] &&
                _vm.displayStyle == 'COCO' &&
                _vm.arr[index] == 'Zusammenfassung'
              ? 'active-z'
              : _vm.active[index] &&
                (_vm.displayStyle == 'PURE' ||
                  _vm.displayStyle == 'SILK' ||
                  _vm.displayStyle == 'COCO') &&
                _vm.arr[index] == 'Zusammenfassung'
              ? 'active-coco-pure-z'
              : '',on:{"click":function($event){return _vm.clickRoom(room, index, _vm.displayStyle)}}},[_vm._v(" "+_vm._s(_vm.arr[index])+" ")])])}),(this.$store.getters.user.loggedIn)?_c('button',{on:{"click":function($event){$event.preventDefault();return _vm.signOut($event)}}},[_vm._v(" Abmelden ")]):_vm._e()],2):_c('div',{staticClass:"crown__rooms--menu",class:_vm.displayStyle == 'COCO' ? 'white-item' : ''},[_vm._l((_vm.rooms),function(room,index){return _c('div',{key:room,class:_vm.arr[index] == 'Wohnen' ||
          _vm.arr[index] == 'Bad' ||
          _vm.arr[index] == 'Grundriss' ||
          _vm.arr[index] == 'Zusammenfassung'
            ? _vm.arr[index]
            : 'none'},[(
            _vm.arr[index] == 'Wohnen' ||
            _vm.arr[index] == 'Bad' ||
            _vm.arr[index] == 'Grundriss' ||
            _vm.arr[index] == 'Zusammenfassung'
          )?_c('button',{class:_vm.active[index] &&
            _vm.displayStyle == 'PURE_BASIC' &&
            _vm.arr[index] != 'Zusammenfassung'
              ? 'active'
              : _vm.active[index] &&
                _vm.displayStyle == 'COCO' &&
                _vm.arr[index] == 'Zusammenfassung'
              ? 'active-z'
              : _vm.active[index] &&
                _vm.displayStyle == 'PURE_BASIC' &&
                _vm.arr[index] == 'Zusammenfassung'
              ? 'active-coco-pure-z'
              : '',on:{"click":function($event){return _vm.clickRoom(room, index, _vm.displayStyle)}}},[_vm._v(" "+_vm._s(_vm.arr[index])+" ")]):_c('button',[_vm._v("asdf")])])}),(this.$store.getters.user.loggedIn)?_c('button',{on:{"click":function($event){$event.preventDefault();return _vm.signOut($event)}}},[_vm._v(" Abmelden ")]):_vm._e()],2)],1),(_vm.moodboard == true)?_c('div',{staticClass:"crown__rooms--moonboard"}):_vm._e(),(_vm.productsModal)?_c('div',{staticClass:"fixed-overlay fixed-overlay__modal"},[_c('div',{staticClass:"modal"},[_c('div',{staticClass:"modal_container"},[_c('div',{staticClass:"close"},[_c('button',{on:{"click":function($event){_vm.productsModal = false}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"10.607","height":"10.607","viewBox":"0 0 10.607 10.607"}},[_c('g',{attrs:{"id":"Компонент_98_45","data-name":"Компонент 98 – 45","transform":"translate(0.354 0.354)"}},[_c('line',{attrs:{"id":"Линия_1895","data-name":"Линия 1895","x2":"14","transform":"translate(0 0) rotate(45)","fill":"none","stroke":"#323a41","stroke-width":"1"}}),_c('line',{attrs:{"id":"Линия_1896","data-name":"Линия 1896","y2":"14","transform":"translate(9.9 0) rotate(45)","fill":"none","stroke":"#323a41","stroke-width":"1"}})])])])]),(_vm.download)?_c('div',[_vm._v("Download - "+_vm._s(_vm.info))]):_vm._e(),(!_vm.download)?_c('products-info',{attrs:{"userLink":_vm.user,"msg":{
            flatID: { id: _vm.flatID },
            roomBook: { id: _vm.room.RoombookID },
            lineItems: { id: _vm.lineItems },
            subLineItems: { id: _vm.pointItemID },
            room: { name: _vm.room.displayName },
            defaultQuantity: { quantity: _vm.defaultQuantity },
            productGroup: _vm.productGroup,
            style: { action: _vm.displayStyle },
            action: _vm.action,
            onlySee: _vm.onlySee,
          }}}):_vm._e()],1)])]):_vm._e(),_c('div',{staticClass:"crown__room--check"},[_c('anderungen',{attrs:{"close":_vm.windowCheck}})],1),(_vm.display)?_c('div',[(this.$store.getters.user.loggedIn != true)?_c('div',{staticClass:"back__flats"},[_c('router-link',{attrs:{"to":{ name: 'Flats' }}},[_vm._v("◄ Zurück zur Wohnungsliste")])],1):_vm._e(),_c('div',[_c('div',{staticClass:"img__view"},[_c('img',{attrs:{"src":_vm.imgView,"alt":""}})])]),(_vm.zusammenfassung)?_c('div',{staticClass:"zsfng"},[_c('zusammenfassung',{attrs:{"roomBook":_vm.info,"setUser":{ key: _vm.setUser },"msg":{
          roomName: { name: _vm.room.displayName },
          roomNumber: { number: _vm.number },
          flatID: { id: _vm.flatID },
          style: { displayStyle: _vm.displayStyle, imgPriceStyle: _vm.imgPriceStyle },
        }}})],1):_vm._e(),(_vm.aussicht)?_c('div',{staticClass:"button__start--img"},_vm._l((_vm.view),function(v,index){return _c('div',{key:index,attrs:{"id":"bg"}},[_c('button',{on:{"click":function($event){_vm.imgView = v.img}}},[_vm._v(_vm._s(v.imgNumber))])])}),0):_vm._e()]):_vm._e(),(!_vm.display)?_c('div',[(_vm.points == null)?_c('div',{staticClass:"img__view",class:_vm.numberImg != 0 ? 'up-img' : ''},[_c('img',{attrs:{"src":_vm.imgView,"alt":"imgView"}})]):_vm._e(),(_vm.points != null && _vm.room.displayName != 'Grundriss')?_c('svg',{staticClass:"img__view--svg",style:('enable-background: new 0 0 ' + _vm.imgSizeWidth + ' ' + _vm.imgSizeHeight),attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","x":"0px","y":"0px","viewBox":'0 0 ' + _vm.imgSizeWidth + ' ' + _vm.imgSizeHeight,"xml:space":"preserve"}},[_c('defs',[_c('filter',{attrs:{"id":"shadow"}},[_c('feDropShadow',{attrs:{"dx":"0","dy":"0","stdDeviation":"3","flood-color":"#222222"}})],1)]),_vm._l((_vm.points.DotsList),function(point,item){return _c('g',{key:item + Math.random()},[_c('circle',{class:'st0 ' + 'point' + item,staticStyle:{"filter":"url(#shadow)"},attrs:{"cx":_vm.points.DotsList[item].PositionX,"cy":_vm.imgSizeHeight - _vm.points.DotsList[item].PositionY,"r":"15","stroke-width":"1","stroke":"#222222"},on:{"click":function($event){return _vm.product(
              _vm.points.DotsList[item].SubLineItemID,
              _vm.points.DotsList[item].LineItemID,
              _vm.points.DotsList[item].Action,
              _vm.points.DotsList[item].NavigationRoomID
            )}}})])})],2):_vm._e(),(_vm.points != null && _vm.room.displayName == 'Grundriss')?_c('svg',{staticClass:"img__view--svg",style:('enable-background: new 0 0 ' + _vm.imgSizeWidth + ' ' + _vm.imgSizeHeight),attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","x":"0px","y":"0px","viewBox":'0 0 ' + _vm.imgSizeWidth + ' ' + _vm.imgSizeHeight,"xml:space":"preserve"}},[_c('defs',[_c('filter',{attrs:{"id":"shadow"}},[_c('feDropShadow',{attrs:{"dx":"0","dy":"0","stdDeviation":"3","flood-color":"#222222"}})],1)]),_vm._l((_vm.points.DotsList),function(point,item){return _c('g',{key:item + Math.random()},[_c('circle',{class:'st0 ' + 'point' + item,staticStyle:{"filter":"url(#shadow)"},attrs:{"cx":_vm.points.DotsList[item].PositionX,"cy":_vm.imgSizeHeight - _vm.points.DotsList[item].PositionY,"r":"36","stroke-width":"1","stroke":"#222222"},on:{"click":function($event){return _vm.product(
              _vm.points.DotsList[item].SubLineItemID,
              _vm.points.DotsList[item].LineItemID,
              _vm.points.DotsList[item].Action,
              _vm.points.DotsList[item].NavigationRoomID
            )}}}),_c('g',{attrs:{"id":"Сгруппировать_5662","data-name":"Сгруппировать 5662","transform":("translate(" + (_vm.points.DotsList[item].PositionX - 89) + ", " + (_vm.imgSizeHeight - _vm.points.DotsList[item].PositionY - 122) + ")")}},[_c('path',{attrs:{"id":"Контур_7269","data-name":"Контур 7269","d":"M89.381,101.89A14.4,14.4,0,0,0,75,116.271c0,9.967,14.4,26.993,14.4,26.993s14.368-17.516,14.368-26.993A14.4,14.4,0,0,0,89.381,101.89Zm4.339,18.592a6.137,6.137,0,1,1,0-8.678,6.119,6.119,0,0,1,0,8.678Zm0,0","fill":"none","stroke":"#323a41","stroke-miterlimit":"10","stroke-width":"1"}})])])})],2):_vm._e(),(_vm.points != null)?_c('div',{staticClass:"img__view",class:_vm.numberImg != 0 ? 'up-img' : ''},[_c('img',{attrs:{"src":_vm.imgView,"alt":"imgView"}})]):_vm._e(),(this.$store.getters.user.loggedIn != true)?_c('div',{staticClass:"back__flats"},[_c('router-link',{attrs:{"to":{ name: 'Flats' }}},[_vm._v("◄ Zurück zur Wohnungsliste")])],1):_vm._e(),(_vm.displayStyle == 'SILK')?_c('div',{staticClass:"crown__rooms--block-img prev"},[_c('button',{staticClass:"prev",on:{"click":function($event){_vm.imgView = _vm.room.StylesData.SILK[_vm.numberImg - 1];
          _vm.styleRoom = 'SILK';
          _vm.numberImg = _vm.numberImg - 1;
          _vm.styleButton(_vm.roomPoints, _vm.styleRoom, _vm.numberImg);}}},[(_vm.numberImg > 0)?_c('img',{attrs:{"src":require("./../assets/prev.svg"),"alt":""}}):_vm._e()])]):_vm._e(),(_vm.displayStyle == 'SILK')?_c('div',{staticClass:"crown__rooms--block-img next"},[(this.room.displayName != 'Grundriss')?_c('button',{staticClass:"next",on:{"click":function($event){_vm.imgView = _vm.room.StylesData.SILK[_vm.numberImg + 1];
          _vm.styleRoom = 'SILK';
          _vm.numberImg = _vm.numberImg + 1;
          _vm.styleButton(_vm.roomPoints, _vm.styleRoom, _vm.numberImg);}}},[(_vm.numberImg < _vm.room.StylesData.SILK.length - 1)?_c('img',{attrs:{"src":require("./../assets/next.svg"),"alt":""}}):_vm._e()]):_vm._e()]):_vm._e(),(_vm.displayStyle == 'COCO')?_c('div',{staticClass:"crown__rooms--block-img prev"},[_c('button',{staticClass:"prev",on:{"click":function($event){_vm.imgView = _vm.room.StylesData.COCO[_vm.numberImg - 1];
          _vm.styleRoom = 'COCO';
          _vm.numberImg = _vm.numberImg - 1;
          _vm.styleButton(_vm.roomPoints, _vm.styleRoom, _vm.numberImg);}}},[(_vm.numberImg > 0)?_c('img',{attrs:{"src":require("./../assets/prev.svg"),"alt":""}}):_vm._e()])]):_vm._e(),(_vm.displayStyle == 'COCO')?_c('div',{staticClass:"crown__rooms--block-img next"},[(this.room.displayName != 'Grundriss')?_c('button',{staticClass:"next",on:{"click":function($event){_vm.imgView = _vm.room.StylesData.COCO[_vm.numberImg + 1];
          _vm.styleRoom = 'COCO';
          _vm.numberImg = _vm.numberImg + 1;
          _vm.styleButton(_vm.roomPoints, _vm.styleRoom, _vm.numberImg);}}},[(_vm.numberImg < _vm.room.StylesData.COCO.length - 1)?_c('img',{attrs:{"src":require("./../assets/next.svg"),"alt":""}}):_vm._e()]):_vm._e()]):_vm._e(),(_vm.displayStyle == 'PURE')?_c('div',{staticClass:"crown__rooms--block-img prev"},[_c('button',{staticClass:"prev",on:{"click":function($event){_vm.imgView = _vm.room.StylesData.PURE[_vm.numberImg - 1];
          _vm.styleRoom = 'PURE';
          _vm.numberImg = _vm.numberImg - 1;
          _vm.styleButton(_vm.roomPoints, _vm.styleRoom, _vm.numberImg);}}},[(_vm.numberImg > 0)?_c('img',{attrs:{"src":require("./../assets/prev.svg"),"alt":""}}):_vm._e()])]):_vm._e(),(_vm.displayStyle == 'PURE')?_c('div',{staticClass:"crown__rooms--block-img next"},[(this.room.displayName != 'Grundriss')?_c('button',{staticClass:"next",on:{"click":function($event){_vm.imgView = _vm.room.StylesData.PURE[_vm.numberImg + 1];
          _vm.styleRoom = 'PURE';
          _vm.numberImg = _vm.numberImg + 1;
          _vm.styleButton(_vm.roomPoints, _vm.styleRoom, _vm.numberImg);}}},[(_vm.numberImg < _vm.room.StylesData.PURE.length - 1)?_c('img',{attrs:{"src":require("./../assets/next.svg"),"alt":""}}):_vm._e()]):_vm._e()]):_vm._e(),_c('div',{staticClass:"crown__rooms--block"},[_c('div',{staticClass:"crown__room--style"},[_c('div',{staticClass:"crown__rooms--room",class:{
            coco: _vm.activeStyle == 'COCO',
            pure: _vm.activeStyle == 'PURE_BASIC' || _vm.activeStyle == 'PURE',
          }},[_c('div',[_vm._v("WOHNUNG "+_vm._s(_vm.number))]),(_vm.visibleStyle == true)?_c('div',{staticClass:"crown__rooms--room-style"},[_vm._v(" Einrichtungsstil: "),_c('button',[_vm._v(" "+_vm._s(_vm.styleName)+" ")])]):_vm._e(),(_vm.visibleStyle == true && _vm.room.displayName == 'Grundriss')?_c('div',{staticClass:"footer-more",on:{"click":function($event){_vm.moodboard = true;
              _vm.visibleStyle = false;
              _vm.konfigurator = true;}}},[(_vm.room.displayName == 'Grundriss')?_c('span',[_vm._v(" Legende ")]):_vm._e(),(_vm.visibleStyle)?_c('span',[_vm._v("▲")]):_vm._e(),(!_vm.visibleStyle)?_c('span',[_vm._v("▼")]):_vm._e()]):_vm._e(),(_vm.visibleStyle == false)?_c('div',{staticClass:"footer-more",on:{"click":function($event){_vm.moodboard = true;
              _vm.visibleStyle = true;
              _vm.konfigurator = false;}}},[_vm._v(" Schließen "),(_vm.visibleStyle)?_c('span',[_vm._v("▲")]):_vm._e(),(!_vm.visibleStyle)?_c('span',[_vm._v("▼")]):_vm._e()]):_vm._e()])]),(_vm.visibleStyle == false && _vm.moodboard == true)?_c('div',{staticClass:"crown__rooms--style-info",class:_vm.displayStyle == 'COCO'
            ? 'coco'
            : _vm.displayStyle == 'PURE'
            ? 'pure'
            : _vm.displayStyle == 'PURE_BASIC'
            ? 'pure'
            : ''}):_vm._e(),(_vm.visibleStyle == false && this.room.displayName == 'Grundriss')?_c('div',{staticClass:"crown__rooms--style-info",class:_vm.displayStyle == 'COCO'
            ? 'coco'
            : _vm.displayStyle == 'PURE'
            ? 'pure'
            : ''},[(_vm.displayStyle == 'SILK')?_c('div',[_c('img',{attrs:{"src":this.room.StylesData.SILK[1],"alt":"","width":"60%"}})]):_vm._e(),(_vm.displayStyle == 'COCO')?_c('div',[_c('img',{attrs:{"src":this.room.StylesData.COCO[1],"alt":"","width":"60%"}})]):_vm._e(),(_vm.displayStyle == 'PURE')?_c('div',[_c('img',{attrs:{"src":this.room.StylesData.PURE[1],"alt":"","width":"60%"}})]):_vm._e(),(_vm.displayStyle == 'PURE_BASIC')?_c('div',[_c('img',{attrs:{"src":this.room.StylesData.PURE[1],"alt":"","width":"60%"}})]):_vm._e()]):_vm._e(),(_vm.visibleStyle == false && _vm.moodboard == false)?_c('div',{staticClass:"crown__rooms--style-info",class:_vm.displayStyle == 'COCO'
            ? 'coco'
            : _vm.displayStyle == 'PURE_BASIC' || _vm.displayStyle == 'PURE'
            ? 'pure'
            : ''},[_c('moodboard',{attrs:{"msg":{
            room: { name: _vm.room.displayName },
            info: _vm.configuratorData,
            flat: _vm.flatID,
            start: _vm.startConfigurator,
          }}})],1):_vm._e()])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lds-ripple"},[_c('div'),_c('div')])}]

export { render, staticRenderFns }