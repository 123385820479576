<template>
  <div
    class="zusammenfassung"
    :class="
      msg.style.displayStyle == 'PURE' || msg.style.displayStyle == 'PURE_BASIC'
        ? 'zusammenfassung-pure'
        : msg.style.displayStyle == 'COCO'
        ? 'zusammenfassung-coco'
        : ''
    "
  >
    <div class="loader" v-if="startLoader">
      <div class="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>

    <div class="outerContainerZu" v-if="!startLoader">
      <div class="tableContainer">
        <div class="tableHeadline">
          <div v-if="msg.style.imgPriceStyle.length != 0">
            <div
              class="img__price--style"
              v-if="msg.style.displayStyle == 'PURE_BASIC'"
            >
              <div class="price__header--block scroll-moodboard">
                <div class="price__header--block-item">
                  <p class="bold" @click="scrollToElement('scroll-moodboard')">
                    MOODBOARD
                  </p>
                </div>
                <div class="price__header--block-item">
                  <p @click="scrollToElement('scroll-grundriss')">GRUNDRISS</p>
                </div>
                <div class="price__header--block-item">
                  <p @click="scrollToElement('scroll-product')">
                    PRODUKTAUSWAHL
                  </p>
                </div>
              </div>

              <div
                class="moodboard__margin"
                v-if="msg.style.displayStyle == 'PURE_BASIC'"
              >
                <img
                  class="moodboard__margin--img"
                  :src="this.$store.getters.configurator.bad.src"
                  alt=""
                />
                <img
                  class="moodboard__margin--img"
                  :src="this.$store.getters.configurator.wohnen.src"
                  alt=""
                />
              </div>

              <div
                v-if="msg.style.displayStyle != 'PURE_BASIC'"
                class="moodboard__margin"
              >
                <img
                  class="moodboard__margin--img"
                  :src="msg.style.imgPriceStyle[0]"
                  alt=""
                  v-if="picked != 'one'"
                  rel="prefetch prerende"
                />
                <img
                  class="moodboard__margin--img"
                  :src="msg.style.imgPriceStyle[1]"
                  alt=""
                  v-if="color == 'color:silver'"
                />
                <img
                  class="moodboard__margin--img"
                  :src="msg.style.imgPriceStyle[3]"
                  alt=""
                  v-if="color == 'color:black'"
                />
              </div>

              <div class="price__header--block scroll-grundriss">
                <div class="price__header--block-item">
                  <p @click="scrollToElement('scroll-moodboard')">MOODBOARD</p>
                </div>
                <div class="price__header--block-item">
                  <p class="bold" @click="scrollToElement('scroll-grundriss')">
                    GRUNDRISS
                  </p>
                </div>
                <div class="price__header--block-item">
                  <p @click="scrollToElement('scroll-product')">
                    PRODUKTAUSWAHL
                  </p>
                </div>
              </div>
              <img :src="msg.style.imgPriceStyle[2]" alt="" />
            </div>

            <div v-if="msg.style.displayStyle != 'PURE_BASIC'">
              <div
                class="img__price--style moodboard__margin--img"
                v-for="(img, indexImg) in msg.style.imgPriceStyle"
                :key="indexImg + Math.random()"
              >
                <div v-if="indexImg == 0">
                  <div class="price__header--block scroll-moodboard">
                    <div class="price__header--block-item">
                      <p
                        class="bold"
                        @click="scrollToElement('scroll-moodboard')"
                      >
                        MOODBOARD
                      </p>
                    </div>
                    <div class="price__header--block-item">
                      <p @click="scrollToElement('scroll-grundriss')">
                        GRUNDRISS
                      </p>
                    </div>
                    <div class="price__header--block-item">
                      <p @click="scrollToElement('scroll-product')">
                        PRODUKTAUSWAHL
                      </p>
                    </div>
                  </div>
                </div>
                <div v-if="indexImg == 2">
                  <div class="price__header--block scroll-grundriss">
                    <div class="price__header--block-item">
                      <p @click="scrollToElement('scroll-moodboard')">
                        MOODBOARD
                      </p>
                    </div>
                    <div class="price__header--block-item">
                      <p
                        class="bold"
                        @click="scrollToElement('scroll-grundriss')"
                      >
                        GRUNDRISS
                      </p>
                    </div>
                    <div class="price__header--block-item">
                      <p @click="scrollToElement('scroll-product')">
                        PRODUKTAUSWAHL
                      </p>
                    </div>
                  </div>
                </div>

                <img
                  :src="img"
                  alt=""
                  v-if="
                    (picked != 'one' && indexImg == 0) ||
                    (indexImg == 0 &&
                      (msg.style.displayStyle == 'COCO' ||
                        msg.style.displayStyle == 'SILK'))
                  "
                  rel="prefetch prerende"
                />
                <img :src="img" alt="" v-if="indexImg != 0" />
              </div>
            </div>
          </div>
          <div v-else>
            <img
              src="http://garmonia.site/_garmonia/temp/crown/booomtown.png"
              alt=""
            />
            <p>Bild noch nicht hochgeladen</p>
            <hr />
          </div>
        </div>

        <div class="price__header--block scroll-product">
          <div class="price__header--block-item">
            <p @click="scrollToElement('scroll-moodboard')">MOODBOARD</p>
          </div>
          <div class="price__header--block-item">
            <p @click="scrollToElement('scroll-grundriss')">GRUNDRISS</p>
          </div>
          <div class="price__header--block-item">
            <p class="bold" @click="scrollToElement('scroll-product')">
              PRODUKTAUSWAHL
            </p>
          </div>
        </div>

        <div class="price" v-if="error != 'empty'">
          <div
            v-for="(roomPrice, index) in price"
            :key="index + Math.random()"
            class="price__header--room"
          >
            <div
              :class="
                index == 'totalPrice'
                  ? 'none'
                  : index == 'Verbaute Designlinie'
                  ? 'none'
                  : index == 'Flur' &&
                    msg.roomNumber.number == '12.01' &&
                    (msg.style.displayStyle == 'PURE' ||
                      msg.style.displayStyle == 'PURE_BASIC')
                  ? 'none'
                  : index == 'Wohnen/Kochen' &&
                    msg.roomNumber.number == '12.01' &&
                    (msg.style.displayStyle == 'PURE' ||
                      msg.style.displayStyle == 'PURE_BASIC')
                  ? 'none'
                  : ''
              "
              v-if="roomPrice[0] != null"
            >
              <details
                v-if="JSON.stringify(roomPrice[0][0].subLineItems) != '[]' && JSON.stringify(roomPrice[0][0].subLineItems[0].productGroup.productOptions) != '[]'"
                open
              >
                <summary>
                  <h2 class="class3" :data-header="index">{{ index }}</h2>
                </summary>
                <div
                  v-for="itemIndex in roomPrice.length"
                  :key="itemIndex + Math.random()"
                >
                  <div
                    v-for="(p, indexP) in roomPrice[itemIndex - 1]"
                    :key="indexP + Math.random()"
                  >
                    <div
                      v-for="(s, indexS) in p.subLineItems"
                      :key="indexS + Math.random()"
                    >
                      <div
                        v-if="
                          msg.style.displayStyle == 'PURE' ||
                          msg.style.displayStyle == 'PURE_BASIC'
                        "
                      >
                        <!-- SelectItem-->
                        <div
                          v-for="(select, indexSelect) in arrSelect"
                          :key="indexSelect + Math.random()"
                        >
                          <div>
                            <div
                              v-for="(options, indexPP) in s.productGroup
                                .productOptions"
                              :key="indexPP + Math.random()"
                            >
                              <div
                                v-for="(
                                  productPrice, indexOptions
                                ) in options.productPrices"
                                :key="indexOptions + Math.random()"
                              >
                                <div
                                  class="price__info"
                                  v-if="
                                    (select.productID ==
                                      productPrice.product.id &&
                                      select.subLineItemsName == s.title &&
                                      select.subLineItemsName != 'Parkett' &&
                                      ((!productPrice.tags.includes(
                                        'color:black'
                                      ) &&
                                        !productPrice.tags.includes(
                                          'color:silver'
                                        )) ||
                                        productPrice.tags.includes(color))) ||
                                    (productPrice.tags.includes(color) &&
                                      select.subLineItemsName == s.title &&
                                      select.subLineItemsName !=
                                        'Türbeschlag') ||
                                    (productPrice.tags.includes(colorWohnen) &&
                                      select.subLineItemsName == s.title &&
                                      select.subLineItemsName ==
                                        'Türbeschlag') ||
                                    (select.subLineItemsName == 'Parkett' &&
                                      select.productID ==
                                        productPrice.product.id &&
                                      msg.roomNumber.number != '12.01')
                                  "
                                  :class="
                                    select.subLineItemsName == 'Parkett'
                                      ? ''
                                      : ''
                                  "
                                >
                                  <div
                                    v-for="(img, itemImgA) in productPrice
                                      .product.images"
                                    :key="itemImgA + Math.random()"
                                    class="price__info--img"
                                    :class="itemImgA != 0 ? 'none' : ''"
                                  >
                                    <img
                                      v-if="itemImgA == 0"
                                      :src="img.thumbUrl"
                                      alt=""
                                    />
                                  </div>
                                  <div class="price__info--item-2">
                                    <p>
                                      {{ productPrice.product.name }}
                                    </p>
                                  </div>
                                  <div class="price__info--item-3">
                                    <p>
                                      {{ s.title }}
                                    </p>
                                  </div>
                                  <div class="price__info--item-4">
                                    <p>
                                      {{ s.defaultQuantity }}
                                      <span
                                        v-if="
                                          Number.isInteger(s.defaultQuantity) ==
                                          false
                                        "
                                        >m2</span
                                      >
                                      <span v-else>Stk.</span>
                                    </p>
                                  </div>
                                  <div
                                    class="price__info--item-5"
                                    v-if="productPrice.priceInCents != 0"
                                  >
                                    <p
                                      v-bind:class="{
                                        class1: productPrice.priceInCents != 0,
                                      }"
                                      :data-sum="
                                        (productPrice.priceInCents / 100) *
                                        s.defaultQuantity
                                      "
                                      :data-product-id="productPrice.product.id"
                                      :data-sub="s.id"
                                      :data-quantity="s.defaultQuantity"
                                      :data-pg="productPrice.id"
                                      :data-index="index"
                                      :data-name="productPrice.product.name"
                                      :data-title="s.title"
                                      :data-quantity-pure="s.defaultQuantity"
                                      :data-sum-pure="
                                        (productPrice.priceInCents / 100) *
                                        s.defaultQuantity
                                      "
                                    >
                                      {{
                                        editSum(
                                          toFixed(
                                            (productPrice.priceInCents / 100) *
                                              s.defaultQuantity
                                          )
                                        )
                                      }}
                                    </p>
                                  </div>

                                  <div
                                    class="price__info--item-5"
                                    v-if="productPrice.priceInCents == 0"
                                  >
                                    <p
                                      v-bind:class="{
                                        class1: productPrice.priceInCents == 0,
                                      }"
                                      :data-sum="0"
                                      :data-product-id="productPrice.product.id"
                                      :data-sub="s.id"
                                      :data-quantity="s.defaultQuantity"
                                      :data-pg="productPrice.id"
                                      :data-index="index"
                                      :data-name="productPrice.product.name"
                                      :data-title="s.title"
                                      :data-quantity-pure="s.defaultQuantity"
                                      :data-sum-pure="0"
                                    >
                                      0,00 €
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- Bodenbelag All Parket -->
                        <div
                          v-for="(select, indexSelect) in arrSelect"
                          :key="indexSelect + Math.random()"
                        >
                          <div>
                            <div
                              v-for="(options, indexPP) in s.productGroup
                                .productOptions"
                              :key="indexPP + Math.random()"
                            >
                              <div
                                v-for="(
                                  productPrice, indexOptions
                                ) in options.productPrices"
                                :key="indexOptions + Math.random()"
                              >
                                <div
                                  class="price__info"
                                  v-if="
                                    (('Bodenbelag' == s.title &&
                                      arrSelect[0].productID ==
                                        productPrice.product.id &&
                                      msg.roomNumber.number == '12.01') ||
                                      ('Bodenbelag' == s.title &&
                                        arrSelect[1].productID ==
                                          productPrice.product.id &&
                                        msg.roomNumber.number == '12.01') ||
                                      ('Bodenbelag' == s.title &&
                                        arrSelect[2].productID ==
                                          productPrice.product.id &&
                                        msg.roomNumber.number == '12.01') ||
                                      ('Bodenbelag' == s.title &&
                                        arrSelect[3].productID ==
                                          productPrice.product.id &&
                                        msg.roomNumber.number == '12.01') ||
                                      ('Bodenbelag' == s.title &&
                                        arrSelect[4].productID ==
                                          productPrice.product.id &&
                                        msg.roomNumber.number == '12.01') ||
                                      ('Bodenbelag' == s.title &&
                                        arrSelect[5].productID ==
                                          productPrice.product.id &&
                                        msg.roomNumber.number == '12.01')) &&
                                    indexSelect == 0
                                  "
                                >
                                  <div class="price__info--img">
                                    <div
                                      v-for="(img, itemImgD) in productPrice
                                        .product.images"
                                      :key="itemImgD + Math.random()"
                                    >
                                      <img
                                        v-if="itemImgD == 0"
                                        :src="img.thumbUrl"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <div class="price__info--item-2">
                                    <p>
                                      {{ productPrice.product.name }}
                                    </p>
                                  </div>
                                  <div class="price__info--item-3">
                                    <p>
                                      {{ s.title }}
                                    </p>
                                  </div>
                                  <div class="price__info--item-4">
                                    <p>
                                      {{ s.defaultQuantity }}
                                      <span
                                        v-if="
                                          Number.isInteger(s.defaultQuantity) ==
                                          false
                                        "
                                        >m2</span
                                      >
                                      <span v-else>Stk.</span>
                                    </p>
                                  </div>
                                  <div
                                    class="price__info--item-5"
                                    v-if="productPrice.priceInCents != 0"
                                  >
                                    <p
                                      v-bind:class="{
                                        class1: productPrice.priceInCents != 0,
                                      }"
                                      :data-sum="
                                        (productPrice.priceInCents / 100) *
                                        s.defaultQuantity
                                      "
                                      :data-product-id="productPrice.product.id"
                                      :data-sub="s.id"
                                      :data-quantity="s.defaultQuantity"
                                      :data-pg="productPrice.id"
                                      :data-index="index"
                                      :data-name="productPrice.product.name"
                                      :data-title="s.title"
                                      :data-quantity-pure="s.defaultQuantity"
                                      :data-sum-pure="
                                        (productPrice.priceInCents / 100) *
                                        s.defaultQuantity
                                      "
                                    >
                                      {{
                                        editSum(
                                          toFixed(
                                            (productPrice.priceInCents / 100) *
                                              s.defaultQuantity
                                          )
                                        )
                                      }}
                                    </p>
                                  </div>
                                  <div
                                    class="price__info--item-5"
                                    v-if="productPrice.priceInCents == 0"
                                  >
                                    <p
                                      v-bind:class="{
                                        class1: productPrice.priceInCents == 0,
                                      }"
                                      :data-sum="0"
                                      :data-product-id="productPrice.product.id"
                                      :data-sub="s.id"
                                      :data-quantity="s.defaultQuantity"
                                      :data-pg="productPrice.id"
                                      :data-index="index"
                                      :data-name="productPrice.product.name"
                                      :data-title="s.title"
                                      :data-quantity-pure="s.defaultQuantity"
                                      :data-sum-pure="0"
                                    >
                                      0,00 €
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- Wandfliese Großformat and Bodenfliese -->
                        <div
                          v-for="(select, indexSelect) in arrSelect"
                          :key="indexSelect + Math.random()"
                        >
                          <div>
                            <div
                              v-for="(options, indexPP) in s.productGroup
                                .productOptions"
                              :key="indexPP + Math.random()"
                            >
                              <div
                                v-for="(
                                  productPrice, indexOptions
                                ) in options.productPrices"
                                :key="indexOptions + Math.random()"
                              >
                                <div
                                  class="price__info"
                                  v-if="
                                    ((s.title == 'Wandfliese Großformat' &&
                                      'none' == grosformat) ||
                                      (s.title == 'Bodenfliese' &&
                                        'none' == grosformat)) &&
                                    indexSelect == 0
                                  "
                                >
                                  <div class="price__info--img">
                                    <div
                                      v-for="(img, itemImgD) in productPrice
                                        .product.images"
                                      :key="itemImgD + Math.random()"
                                    >
                                      <img
                                        v-if="itemImgD == 0"
                                        :src="img.thumbUrl"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <div class="price__info--item-2">
                                    <p>
                                      {{ productPrice.product.name }}
                                    </p>
                                  </div>
                                  <div class="price__info--item-3">
                                    <p>
                                      {{ s.title }}
                                    </p>
                                  </div>
                                  <div class="price__info--item-4">
                                    <p>
                                      {{ s.defaultQuantity }}
                                      <span
                                        v-if="
                                          Number.isInteger(s.defaultQuantity) ==
                                          false
                                        "
                                        >m2</span
                                      >
                                      <span v-else>Stk.</span>
                                    </p>
                                  </div>
                                  <div
                                    class="price__info--item-5"
                                    v-if="productPrice.priceInCents != 0"
                                  >
                                    <p
                                      v-bind:class="{
                                        class1: productPrice.priceInCents != 0,
                                      }"
                                      :data-sum="
                                        (productPrice.priceInCents / 100) *
                                        s.defaultQuantity
                                      "
                                      :data-product-id="productPrice.product.id"
                                      :data-sub="s.id"
                                      :data-quantity="s.defaultQuantity"
                                      :data-pg="productPrice.id"
                                      :data-index="index"
                                      :data-name="productPrice.product.name"
                                      :data-title="s.title"
                                      :data-quantity-pure="s.defaultQuantity"
                                      :data-sum-pure="
                                        (productPrice.priceInCents / 100) *
                                        s.defaultQuantity
                                      "
                                    >
                                      {{
                                        editSum(
                                          toFixed(
                                            (productPrice.priceInCents / 100) *
                                              s.defaultQuantity
                                          )
                                        )
                                      }}
                                    </p>
                                  </div>
                                  <div
                                    class="price__info--item-5"
                                    v-if="productPrice.priceInCents == 0"
                                  >
                                    <p
                                      v-bind:class="{
                                        class1: productPrice.priceInCents == 0,
                                      }"
                                      :data-sum="0"
                                      :data-product-id="productPrice.product.id"
                                      :data-sub="s.id"
                                      :data-quantity="s.defaultQuantity"
                                      :data-pg="productPrice.id"
                                      :data-index="index"
                                      :data-name="productPrice.product.name"
                                      :data-title="s.title"
                                      :data-quantity-pure="s.defaultQuantity"
                                      :data-sum-pure="0"
                                    >
                                      0,00 €
                                    </p>
                                  </div>
                                </div>

                                <div
                                  class="price__info"
                                  v-if="
                                    ((s.title == 'Wandfliese Großformat' &&
                                      productPrice.product.id == grosformat) ||
                                      (s.title == 'Bodenfliese' &&
                                        productPrice.product.id ==
                                          grosformat)) &&
                                    indexSelect == 0
                                  "
                                >
                                  <div class="price__info--img">
                                    <div
                                      v-for="(img, itemImgD) in productPrice
                                        .product.images"
                                      :key="itemImgD + Math.random()"
                                    >
                                      <img
                                        v-if="itemImgD == 0"
                                        :src="img.thumbUrl"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <div class="price__info--item-2">
                                    <p>
                                      {{ productPrice.product.name }}
                                    </p>
                                  </div>
                                  <div class="price__info--item-3">
                                    <p>
                                      {{ s.title }}
                                    </p>
                                  </div>
                                  <div class="price__info--item-4">
                                    <p>
                                      {{ s.defaultQuantity }}
                                      <span
                                        v-if="
                                          Number.isInteger(s.defaultQuantity) ==
                                          false
                                        "
                                        >m2</span
                                      >
                                      <span v-else>Stk.</span>
                                    </p>
                                  </div>
                                  <div
                                    class="price__info--item-5"
                                    v-if="productPrice.priceInCents != 0"
                                  >
                                    <p
                                      v-bind:class="{
                                        class1: productPrice.priceInCents != 0,
                                      }"
                                      :data-sum="
                                        (productPrice.priceInCents / 100) *
                                        s.defaultQuantity
                                      "
                                      :data-product-id="productPrice.product.id"
                                      :data-sub="s.id"
                                      :data-quantity="s.defaultQuantity"
                                      :data-pg="productPrice.id"
                                      :data-index="index"
                                      :data-name="productPrice.product.name"
                                      :data-title="s.title"
                                      :data-quantity-pure="s.defaultQuantity"
                                      :data-sum-pure="
                                        (productPrice.priceInCents / 100) *
                                        s.defaultQuantity
                                      "
                                    >
                                      {{
                                        editSum(
                                          toFixed(
                                            (productPrice.priceInCents / 100) *
                                              s.defaultQuantity
                                          )
                                        )
                                      }}
                                    </p>
                                  </div>
                                  <div
                                    class="price__info--item-5"
                                    v-if="productPrice.priceInCents == 0"
                                  >
                                    <p
                                      v-bind:class="{
                                        class1: productPrice.priceInCents == 0,
                                      }"
                                      :data-sum="0"
                                      :data-product-id="productPrice.product.id"
                                      :data-sub="s.id"
                                      :data-quantity="s.defaultQuantity"
                                      :data-pg="productPrice.id"
                                      :data-index="index"
                                      :data-name="productPrice.product.name"
                                      :data-title="s.title"
                                      :data-quantity-pure="s.defaultQuantity"
                                      :data-sum-pure="0"
                                    >
                                      0,00 €
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- All -->
                        <div
                          v-for="(select, indexArrSelect) in arrSelect"
                          :key="indexArrSelect + Math.random()"
                          class="tempp"
                        >
                          <div>
                            <div
                              v-for="(options, indexPPG) in s.productGroup
                                .productOptions"
                              :key="indexPPG + Math.random()"
                              class="ttt"
                            >
                              <div
                                v-for="(
                                  productPrice, indexOptions
                                ) in options.productPrices"
                                :key="indexOptions + Math.random()"
                              >
                                <div
                                  class="price__info"
                                  v-if="
                                    arrSelect[0].subLineItemsName != s.title &&
                                    arrSelect[0].productID !=
                                      productPrice.product.id &&
                                    s.title != 'Wandfliese Großformat' &&
                                    s.title != 'Wandfliese Kleinformat' &&
                                    s.title != 'Bodenfliese' &&
                                    s.title != 'Bodenbelag' &&
                                    arrSelect[1].subLineItemsName != s.title &&
                                    arrSelect[1].productID !=
                                      productPrice.product.id &&
                                    s.title != 'Wandfliese Großformat' &&
                                    s.title != 'Wandfliese Kleinformat' &&
                                    s.title != 'Bodenfliese' &&
                                    s.title != 'Bodenbelag' &&
                                    arrSelect[2].subLineItemsName != s.title &&
                                    arrSelect[2].productID !=
                                      productPrice.product.id &&
                                    s.title != 'Wandfliese Großformat' &&
                                    s.title != 'Wandfliese Kleinformat' &&
                                    s.title != 'Bodenfliese' &&
                                    s.title != 'Bodenbelag' &&
                                    arrSelect[3].subLineItemsName != s.title &&
                                    arrSelect[3].productID !=
                                      productPrice.product.id &&
                                    s.title != 'Wandfliese Großformat' &&
                                    s.title != 'Wandfliese Kleinformat' &&
                                    s.title != 'Bodenfliese' &&
                                    s.title != 'Bodenbelag' &&
                                    arrSelect[4].subLineItemsName != s.title &&
                                    arrSelect[4].productID !=
                                      productPrice.product.id &&
                                    s.title != 'Wandfliese Großformat' &&
                                    s.title != 'Wandfliese Kleinformat' &&
                                    s.title != 'Bodenfliese' &&
                                    s.title != 'Bodenbelag' &&
                                    arrSelect[5].subLineItemsName != s.title &&
                                    arrSelect[5].productID !=
                                      productPrice.product.id &&
                                    s.title != 'Wandfliese Großformat' &&
                                    s.title != 'Wandfliese Kleinformat' &&
                                    s.title != 'Bodenfliese' &&
                                    s.title != 'Bodenbelag' &&
                                    ((!productPrice.tags.includes(
                                      'color:black'
                                    ) &&
                                      !productPrice.tags.includes(
                                        'color:silver'
                                      )) ||
                                      s.title == 'Bodenbelag' ||
                                      (productPrice.tags.includes(color) &&
                                        s.title != 'Schalterprogramm KNX') ||
                                      (productPrice.tags.includes(
                                        colorWohnen
                                      ) &&
                                        s.title == 'Schalterprogramm KNX')) &&
                                    indexArrSelect == 0
                                  "
                                >
                                  <div class="price__info--img">
                                    <div
                                      v-for="(img, itemImgB) in productPrice
                                        .product.images"
                                      :key="itemImgB + Math.random()"
                                    >
                                      <img
                                        v-if="itemImgB == 0"
                                        :src="img.thumbUrl"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <div class="price__info--item-2">
                                    <p style="color: #666">
                                      {{ productPrice.product.name }}
                                    </p>
                                  </div>
                                  <div class="price__info--item-3">
                                    <p style="color: #666">
                                      {{ s.title }}
                                    </p>
                                  </div>
                                  <div class="price__info--item-4">
                                    <p style="color: #666">
                                      {{ s.defaultQuantity }}
                                      <span
                                        v-if="
                                          Number.isInteger(s.defaultQuantity) ==
                                          false
                                        "
                                        >m2</span
                                      >
                                      <span v-else>Stk.</span>
                                    </p>
                                  </div>
                                  <div
                                    class="price__info--item-5"
                                    v-if="productPrice.priceInCents != 0"
                                  >
                                    <p
                                      style="color: #666"
                                      v-bind:class="{
                                        class1: productPrice.priceInCents != 0,
                                      }"
                                      :data-sum="
                                        (productPrice.priceInCents / 100) *
                                        s.defaultQuantity
                                      "
                                      :data-product-id="productPrice.product.id"
                                      :data-sub="s.id"
                                      :data-quantity="s.defaultQuantity"
                                      :data-pg="productPrice.id"
                                      :data-index="index"
                                      :data-name="productPrice.product.name"
                                      :data-title="s.title"
                                      :data-quantity-pure="s.defaultQuantity"
                                      :data-sum-pure="
                                        (productPrice.priceInCents / 100) *
                                        s.defaultQuantity
                                      "
                                    >
                                      {{
                                        editSum(
                                          toFixed(
                                            (productPrice.priceInCents / 100) *
                                              s.defaultQuantity
                                          )
                                        )
                                      }}
                                    </p>
                                  </div>
                                  <div
                                    class="price__info--item-5"
                                    v-if="productPrice.priceInCents == 0"
                                  >
                                    <p
                                      v-bind:class="{
                                        class1: productPrice.priceInCents == 0,
                                      }"
                                      :data-sum="0"
                                      :data-product-id="productPrice.product.id"
                                      :data-sub="s.id"
                                      :data-quantity="s.defaultQuantity"
                                      :data-pg="productPrice.id"
                                      :data-index="index"
                                      :data-name="productPrice.product.name"
                                      :data-title="s.title"
                                      :data-quantity-pure="s.defaultQuantity"
                                      :data-sum-pure="0"
                                    >
                                      0,00 €
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div
                          v-for="(options, indexPPC) in s.productGroup
                            .productOptions"
                          :key="indexPPC + Math.random()"
                        >
                          <div
                            v-for="(
                              productPrice, indexOptions
                            ) in options.productPrices"
                            :key="indexOptions + Math.random()"
                          >
                            <div class="price__info">
                              <div class="price__info--img">
                                <div
                                  v-for="(img, itemImgC) in productPrice.product
                                    .images"
                                  :key="itemImgC + Math.random()"
                                >
                                  <img
                                    v-if="itemImgC == 0"
                                    :src="img.thumbUrl"
                                    alt=""
                                  />
                                </div>
                              </div>

                              <div class="price__info--item-2">
                                <p>
                                  {{ productPrice.product.name }}
                                </p>
                              </div>
                              <div class="price__info--item-3">
                                <p>{{ s.title }}</p>
                              </div>
                              <div class="price__info--item-4">
                                <p>
                                  {{ s.defaultQuantity }}
                                  <span
                                    v-if="
                                      Number.isInteger(s.defaultQuantity) ==
                                      false
                                    "
                                    >m2</span
                                  >
                                  <span v-else>Stk.</span>
                                </p>
                              </div>
                              <div
                                class="price__info--item-5"
                                v-if="productPrice.priceInCents != 0"
                                v-bind:class="{
                                  class2: productPrice.priceInCents != 0,
                                }"
                                :data-sum="
                                  (productPrice.priceInCents / 100) *
                                  s.defaultQuantity
                                "
                                :data-product-id="productPrice.product.id"
                                :data-sub="s.id"
                                :data-quantity="s.defaultQuantity"
                                :data-pg="productPrice.id"
                                :data-index="index"
                                :data-name="productPrice.product.name"
                                :data-title="s.title"
                                :data-quantity-pure="s.defaultQuantity"
                                :data-sum-pure="
                                  (productPrice.priceInCents / 100) *
                                  s.defaultQuantity
                                "
                              >
                                <p>
                                  {{
                                    editSum(
                                      toFixed(
                                        (productPrice.priceInCents / 100) *
                                          s.defaultQuantity
                                      )
                                    )
                                  }}
                                </p>
                              </div>
                              <div
                                class="price__info--item-5"
                                v-if="productPrice.priceInCents == 0"
                                v-bind:class="{
                                  class2: productPrice.priceInCents == 0,
                                }"
                                :data-sum="0"
                                :data-product-id="productPrice.product.id"
                                :data-sub="s.id"
                                :data-quantity="s.defaultQuantity"
                                :data-pg="productPrice.id"
                                :data-index="index"
                                :data-name="productPrice.product.name"
                                :data-title="s.title"
                                :data-quantity-pure="s.defaultQuantity"
                                :data-sum-pure="0"
                              >
                                <p>0,00 €</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </details>
            </div>
          </div>
        </div>

        <div class="price__sum">
          <div class="price__sum--header">
            <p>WOHNUNG {{ msg.roomNumber.number }}</p>
          </div>
          <div class="price">
            <div class="price__header--room-total">
              <div class="price__info--total">
                <div class="price__info--item-4 total-price">
                  <h2>Kaufpreis Wohnung:</h2>
                </div>
                <div class="price__info--item-5">
                  <h2>
                    {{ editSum(this.$store.getters.cost) }}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div
            class="price"
            v-if="
              this.msg.style.displayStyle == 'PURE_BASIC'
            "
          >
            <div class="price__header--room-total">
              <div class="price__info--total">
                <div class="price__info--item-4 total-price">
                  <div>
                    <input type="radio" id="one" value="one" v-model="picked" />
                    <label for="one"
                      >Style Pure Basic (ohne Küche und optionalen
                      Möbeln)</label
                    >
                    <br />
                    <input type="radio" id="two" value="two" v-model="picked" />
                    <label for="two"
                      >Style Pure (mit Küche und optionalen Möbeln)</label
                    >
                    <br />
                  </div>
                </div>
                <div class="price__info--item-5">
                  <div v-if="picked == 'one'">
                    <h2>0,00 €</h2>
                  </div>
                  <div v-if="picked == 'two'">
                    <h2>
                      {{
                        editSum(
                          toFixed(
                            (kuche / 100) * this.$store.getters.framedMeters
                          )
                        )
                      }}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="price">
            <div class="price__header--room-total">
              <div class="price__info--total">
                <div class="price__info--item-4 total-price">
                  <h2>Summe Aufpreis:</h2>
                </div>
                <div class="price__info--item-5">
                  <h2
                    v-if="
                      msg.style.displayStyle != 'PURE' &&
                      msg.style.displayStyle != 'PURE_BASIC'
                    "
                  >
                    {{
                      editSum(
                        (verbauteDesignlinie / 100) *
                          this.$store.getters.framedMeters
                      )
                    }}
                  </h2>
                  <h2
                    v-if="
                      msg.style.displayStyle == 'PURE' ||
                      msg.style.displayStyle == 'PURE_BASIC'
                    "
                  >
                    {{ editSum(verbauteDesignlinie) }}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div class="price gesamtsumme">
            <div class="price__header--room-total">
              <div class="price__info--total">
                <div class="price__info--item-4 total-price">
                  <h2>Gesamtsumme:</h2>
                </div>
                <div class="price__info--item-5">
                  <div
                    v-if="
                      picked == 'one' &&
                      msg.style.displayStyle != 'PURE' &&
                      msg.style.displayStyle != 'PURE_BASIC'
                    "
                  >
                    <h2>
                      {{
                        editSum(
                          Number(this.$store.getters.cost) +
                            (verbauteDesignlinie / 100) *
                              this.$store.getters.framedMeters
                        )
                      }}
                    </h2>
                  </div>
                  <div
                    v-if="
                      picked == 'one' &&
                      (msg.style.displayStyle == 'PURE' ||
                        msg.style.displayStyle == 'PURE_BASIC')
                    "
                  >
                    <h2>
                      {{
                        editSum(
                          Number(this.$store.getters.cost) + verbauteDesignlinie
                        )
                      }}
                    </h2>
                  </div>
                  <div
                    v-if="
                      picked == 'two' &&
                      (msg.style.displayStyle == 'PURE' ||
                        msg.style.displayStyle == 'PURE_BASIC')
                    "
                  >
                    <h2>
                      {{
                        editSum(
                          verbauteDesignlinie +
                            Number(this.$store.getters.cost) +
                            (kuche / 100) * this.$store.getters.framedMeters
                        )
                      }}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <vue-html2pdf
      class="pdf-content"
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      :html-to-pdf-options="htmlToPdfOptions"
      @progress="onProgress($event)"
      @startPagination="startPagination()"
      @hasPaginated="hasPaginated()"
      @beforeDownload="beforeDownload($event)"
      @hasDownloaded="hasDownloaded($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <div class="select-pdf" id="document">
          <div class="header">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/cadman-63dc7.appspot.com/o/Images%2F0f9e7436-08ee-45cd-8c25-9ebf7a169799.jpg?alt=media&token=4a547210-1599-47d5-9ced-410b4ff06c26"
              alt=""
              width="200"
            />

            <img src="../assets/logo-dark.png" alt="" />
            <div>
              WOHNUNG {{ msg.roomNumber.number }} /
              {{ msg.style.displayStyle }} /
              {{ new Date() | moment("dddd, MMMM Do YYYY") }}
            </div>

            <img
              v-if="msg.style.displayStyle == 'SILK'"
              class="styles"
              src="../assets/styles/silk-1.jpg"
              alt=""
            />
            <img
              v-if="msg.style.displayStyle == 'SILK'"
              class="styles"
              src="../assets/styles/silk-2.jpg"
              alt=""
            />
            <img
              v-if="msg.style.displayStyle == 'COCO'"
              class="styles"
              src="../assets/styles/coco-1.jpg"
              alt=""
            />
            <img
              v-if="msg.style.displayStyle == 'COCO'"
              class="styles"
              src="../assets/styles/coco-2.jpg"
              alt=""
            />
            <img
              v-if="msg.style.displayStyle == 'PURE' && picked != 'one'"
              class="styles"
              src="../assets/styles/pure-2.jpg"
              alt=""
            />
            <img
              v-if="msg.style.displayStyle == 'PURE'"
              class="styles"
              src="../assets/styles/pure-1.jpg"
              alt=""
            />
            <img
              v-if="msg.style.displayStyle == 'PURE_BASIC' && picked != 'one'"
              class="styles"
              src="../assets/styles/pure-2.jpg"
              alt=""
            />
            <img
              v-if="
                msg.style.displayStyle == 'PURE_BASIC' &&
                color == 'color:silver'
              "
              class="styles"
              src="../assets/styles/pure-1.jpg"
              alt=""
            />
            <img
              v-if="
                msg.style.displayStyle == 'PURE_BASIC' && color == 'color:black'
              "
              class="styles"
              src="../assets/styles/pure-1black.jpg"
              alt=""
            />

            <img
              class="styles flats break"
              :src="
                getImgUrl(msg.roomNumber.number + '-' + msg.style.displayStyle)
              "
              v-bind:alt="msg.roomNumber.number + '-' + msg.style.displayStyle"
            />
          </div>

          <div v-for="(h, hIndex) in itemPdfHeaders" :key="hIndex">
            <div class="header__pdf break">{{ h }}</div>
            <div v-for="(m, i) in itemPdf" :key="i" class="break">
              <div v-if="m.index == h" class="document__pdf">
                <div class="name">
                  {{ m.name }}
                </div>
                <div>
                  {{ m.description }}
                </div>
                <div class="sum">
                  {{ m.quantity }}
                  <span v-if="Number.isInteger(Number(m.quantity)) == false"
                    >m2</span
                  >
                  <span v-else>Stk.</span>
                </div>
                <div class="sum">
                  {{ editSum(m.sum) }}
                </div>
              </div>
            </div>
          </div>

          <div class="price__sum pdf break">
            <div class="price">
              <div class="price__header--room-total">
                <div class="price__info--total">
                  <div class="price__info--item-4 total-price">
                    Kaufpreis Wohnung:
                  </div>
                  <div class="price__info--item-5">
                    {{ editSum(this.$store.getters.cost) }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="price"
              v-if="
                this.msg.style.displayStyle == 'PURE' ||
                this.msg.style.displayStyle == 'PURE_BASIC'
              "
            >
              <div class="price__header--room-total">
                <div class="price__info--total">
                  <div class="price__info--item-4 total-price">
                    <div>
                      <input
                        type="radio"
                        id="one"
                        value="one"
                        v-model="picked"
                      />
                      <label for="one"
                        >Style Pure Basic (ohne Küche und optionalen
                        Möbeln)</label
                      >
                      <br />
                      <input
                        type="radio"
                        id="two"
                        value="two"
                        v-model="picked"
                      />
                      <label for="two"
                        >Style Pure (mit Küche und optionalen Möbeln)</label
                      >
                      <br />
                    </div>
                  </div>
                  <div class="price__info--item-5">
                    <div v-if="picked == 'one'">0,00 €</div>
                    <div v-if="picked == 'two'">
                      {{
                        editSum(
                          toFixed(
                            (kuche / 100) * this.$store.getters.framedMeters
                          )
                        )
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="price">
              <div class="price__header--room-total">
                <div class="price__info--total">
                  <div class="price__info--item-4 total-price">
                    Summe Aufpreis:
                  </div>
                  <div class="price__info--item-5">
                    <div
                      v-if="
                        msg.style.displayStyle != 'PURE' &&
                        msg.style.displayStyle != 'PURE_BASIC'
                      "
                    >
                      {{
                        editSum(
                          (verbauteDesignlinie / 100) *
                            this.$store.getters.framedMeters
                        )
                      }}
                    </div>
                    <div
                      v-if="
                        msg.style.displayStyle == 'PURE' ||
                        msg.style.displayStyle == 'PURE_BASIC'
                      "
                    >
                      {{ editSum(verbauteDesignlinie) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="price gesamtsumme">
              <div class="price__header--room-total">
                <div class="price__info--total">
                  <div class="price__info--item-4 total-price">
                    Gesamtsumme:
                  </div>
                  <div class="price__info--item-5">
                    <div
                      v-if="
                        picked == 'one' &&
                        msg.style.displayStyle != 'PURE' &&
                        msg.style.displayStyle != 'PURE_BASIC'
                      "
                    >
                      {{
                        editSum(
                          Number(this.$store.getters.cost) +
                            (verbauteDesignlinie / 100) *
                              this.$store.getters.framedMeters
                        )
                      }}
                    </div>
                    <div
                      v-if="
                        picked == 'one' &&
                        (msg.style.displayStyle == 'PURE' ||
                          msg.style.displayStyle == 'PURE_BASIC')
                      "
                    >
                      {{
                        editSum(
                          Number(this.$store.getters.cost) + verbauteDesignlinie
                        )
                      }}
                    </div>
                    <div
                      v-if="
                        picked == 'two' &&
                        (msg.style.displayStyle == 'PURE' ||
                          msg.style.displayStyle == 'PURE_BASIC')
                      "
                    >
                      {{
                        editSum(
                          verbauteDesignlinie +
                            Number(this.$store.getters.cost) +
                            (kuche / 100) * this.$store.getters.framedMeters
                        )
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="info break">
            Alle Maße und Angaben sind freibleibend und unverbindlich. Geringe
            Abweichungen hinsichtlich der Wohnflächenberechnung sind baubedingt
            möglich. Ausstattungsbeispiele dienen lediglich als
            Gestaltungsvorschläge, ein Anspruch auf diese Leistungen, soweit
            diese nicht in der Baubeschreibung enthalten sind, besteht nicht.
            Die mit Abschluss eines Kaufvertrages verbundenen Kosten, wie z.B.
            die Notarkosten, Gerichtsgebühren und Grunderwerbsteuer sind nicht
            in den Kaufpreisen enthalten und im Falle des Erwerbs vom Käufer zu
            tragen.
            <br /><b>- Provisionsfrei für den Käufer -</b>
          </div>
        </div>
      </section>
    </vue-html2pdf>

    <div class="crown__rooms--block">
      <div class="crown__room--style">
        <div
          class="crown__rooms--room"
          :class="
            msg.style.displayStyle == 'PURE' ||
            msg.style.displayStyle == 'PURE_BASIC'
              ? 'pure'
              : msg.style.displayStyle == 'COCO'
              ? 'coco'
              : ''
          "
        >
          <div>{{ msg.roomName.name }} {{ msg.roomNumber.number }}</div>
          <div
            v-if="visibleStyle == true"
            v-on:click="visibleStyle = false"
            class="footer-more"
          >
            Ihr persönliches Exposé
            <span v-if="visibleStyle">&#9650;</span>
            <span v-if="!visibleStyle">&#9660;</span>
          </div>
          <div
            v-if="visibleStyle == false"
            v-on:click="visibleStyle = true"
            class="footer-more"
          >
            Schließen
            <span v-if="visibleStyle">&#9650;</span>
            <span v-if="!visibleStyle">&#9660;</span>
          </div>
        </div>
      </div>
      <div
        slot="share-section"
        class="crown__rooms--style-info share-section"
        v-if="visibleStyle == false"
      >
        <div class="header-padding">
          <div class="col">
            <a :href="href" @click="copyLink()">
              <img src="./../assets/pin.png" alt="" />
              <p>Einen Link teilen</p>
            </a>
            <!-- The text field -->
            <input class="link-input" type="text" :value="link" id="myInput" />
          </div>
          <div class="col">
            <a :href="href" @click="exportToPDF()">
              <img src="./../assets/shar-4.png" alt="" />
              <p>Anschauen</p>
            </a>
          </div>
          <div class="col">
            <a :href="href" @click="saveToPDF()">
              <img src="./../assets/shar-3.png" alt="" />
              <p>Herunterladen</p>
            </a>
          </div>
          <div class="col">
            <a href="mailto:test@test.test">
              <img src="./../assets/shar-1.png" alt="" />
              <p>Versenden</p>
            </a>
          </div>
          <div class="col">
            <ShareNetwork
              network="facebook"
              url="http://localhost:8080"
              title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
              description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
              quote="The hot reload is so fast it\'s near instant. - Evan You"
              hashtags=""
            >
              <img src="./../assets/shar-2.png" alt="" />
              <p>Auf Facebook teilen</p>
            </ShareNetwork>
          </div>
        </div>
      </div>

      <!--      <share-section v-if="loginUser" slot="share-section" />-->
      <!--      <login-section v-else slot="login-section" />-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
//import Vue from 'vue'
//import VueSocialSharing from 'vue-social-sharing'
import axios from 'axios';
import moment from 'moment'
import VueHtml2pdf from 'vue-html2pdf'
import html2pdf from 'html2pdf.js'
//import html2canvas from 'html2canvas';

//import BD_SILK from './../bd/z9954SILK.json'
//import BD_COCO from './../bd/z9954COCO.json'
//import BD_PURE from './../bd/z9954PURE.json'
// import ShareSection from "@/components/ShareSection";
// import LoginSection from "@/components/LoginSection";
//const query = 'https://us-central1-cadman-63dc7.cloudfunctions.net/returnBayerSelections?localStorage='
//const queryBayerId = 'https://us-central1-cadman-63dc7.cloudfunctions.net/returnBayerSelectionsForAuthUsers?localStorage='

//Vue.use(VueSocialSharing)

//Vue.use(VueHtmlToPaper);

export default {

  components: { VueHtml2pdf },

  data: function () {
    return {
      startLoader: true,
      loginUser: true,
      isActive: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
      },
      visibleStyle: true,
      showVisibleStyle: true,
      info: [],
      flat: [],
      room: [],
      hover: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
      },
      href: '',
      link: '',
      silkPrice: [],
      cocoPrice: [],
      purePrice: [],
      price: [],
      priceSilk: [],
      countPos: 0,
      urlQuery: '',
      gesamtpreis: 0,
      verbauteDesignlinie: 0,
      gesamt: 0,
      kuche: 0,
      picked: 'one',
      arrSelect: [],
      color: 'color:silver',
      colorWohnen: 'color:silver',
      sum: [],
      mutationsBayer: [],
      grosformat: '77899',
      count: 0,
      sumUser: 0,
      error: '',
      itemPdf: [],
      itemPdfHeaders: [],
      imgPreUrl: '',


      htmlToPdfOptions: {
        pagebreak: { mode: 'css' },
        margin: [0.5, 0, 0.5, 0],
        filename: `hehehe.pdf`,
        enableLinks: false,
        html2canvas: {
          scale: 1,
          useCORS: true
        },
        jsPDF: {
          unit: 'in',
          format: 'a4',
          orientation: 'portrait'
        }
      }
    }
  },
  props: {
    cost: {},
    roomBook: {},
    setUser: {},
    msg: {
      roomName: {},
      roomNumber: {},
      flatID: {},
      style: {}
    }
  },
  name: 'Zusammenfassung',
  filters: {
    formatUnix: function (value) {
      if (value) {
        return moment.unix(value).format('MM-DD-YYYY')
      }
    }
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user"
    })
  },
  methods: {
    saveToPDF() {
      const element = document.getElementById('document');
      const opt = {
        margin: [0.5, 0, 0.5, 0],
        pagebreak: { avoid: '.break' },
        filename: 'WOHNUNG' + JSON.stringify(this.msg.roomNumber.number) + '.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: {
          scale: 2.5, letterRendering: true,
          useCORS: true,
          allowTaint: true
        },
        jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
      };
      html2pdf()
        .set(opt)
        .from(element)
        .save();
    },
    exportToPDF() {
      const element = document.getElementById('document');
      const opt = {
        margin: [0.5, 0, 0.5, 0],
        pagebreak: { avoid: '.break' },
        filename: 'myfile.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: {
          scale: 2.5, letterRendering: true,
          useCORS: true,
          allowTaint: true
        },
        jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
      };
      html2pdf()
        .set(opt)
        .from(element).toPdf().get('pdf').then(function (pdf) {
          window.open(pdf.output('bloburl'), '_blank');
        });
    },
    scrollToElement(val) {
      const el = this.$el.getElementsByClassName(val)[0];
      if (el) {
        el.scrollIntoView();
      }
    },
    /*
    addItem() {
      var elementHeaders = document.getElementsByClassName('class3')
      var elements = document.getElementsByClassName('class2')
      setTimeout(() => {
        for (let i = 1; i < elementHeaders.length; i++) {
          this.itemPdfHeaders.push(elementHeaders.item(i).attributes.item(0).value)
        }

        for (let i = 2; i < elements.length; i++) {
          this.itemPdf.push({
            index: elements.item(i).attributes.item(0).value,
            name: elements.item(i).attributes.item(1).value,
            description: elements.item(i).attributes.item(2).value,
            quantity: elements.item(i).attributes.item(3).value,
            sum: elements.item(i).attributes.item(4).value
          })
        }
      }, 3000);

    },
    */
    changeColor(styleFlat) {
      var elementHeaders = document.getElementsByClassName('class3')
      var elements

      if (styleFlat == 'PURE' || styleFlat == 'PURE_BASIC') {
        elements = document.getElementsByClassName('class1')
      }
      else {
        elements = document.getElementsByClassName('class2')
      }

      setTimeout(() => {

        for (let i = 1; i < elementHeaders.length; i++) {
          this.itemPdfHeaders.push(elementHeaders.item(i).attributes.item(0).value)
        }

        for (let i = 2; i < elements.length; i++) {
          this.itemPdf.push({
            index: elements.item(i).attributes.item(5).value,
            name: elements.item(i).attributes.item(6).value,
            description: elements.item(i).attributes.item(7).value,
            quantity: elements.item(i).attributes.item(8).value,
            sum: elements.item(i).attributes.item(9).value
          })
        }

        for (let i = 0; i < elements.length; i++) {
          if ((styleFlat == 'PURE' || styleFlat == 'PURE_BASIC') && i == 1) {
            continue
          }
          this.sumUser += Number(elements.item(i).attributes.item('data-sum').value)
          console.log(elements.item(i).attributes.item(0).value)
          this.mutationsBayer.push({
            lineItemId: elements.item(i).attributes.item(2).value,
            buyerId: localStorage.getItem('crownBayerId'),
            productId: elements.item(i).attributes.item(1).value,
            quantity: elements.item(i).attributes.item(3).value
          })
        }

        if (styleFlat == 'PURE' || styleFlat == 'PURE_BASIC') {
          this.verbauteDesignlinie = this.sumUser
          this.gesamt = this.gesamtpreis + this.verbauteDesignlinie
        }

        if (this.user.loggedIn && this.user.data.displayName != 'Superadmin') {
          var url = "https://us-central1-cadman-63dc7.cloudfunctions.net/returnSelecedItemsForAuthUsers";
          var xhr = new XMLHttpRequest();
          xhr.open("POST", url);
          xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
          xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
              console.log(xhr.status);
              console.log(xhr.responseText);
            }
          };
          var data = "selectiosList=" + JSON.stringify(this.mutationsBayer) + "&localStorage=" + localStorage.getItem('crown') + "&style=" + styleFlat + "&configurator=" + encodeURIComponent(JSON.stringify(this.$store.getters.configurator)) + "&unitId=" + localStorage.getItem('crownBayerFlatId');
          xhr.send(data);
        }

        console.log(JSON.stringify(this.mutationsBayer))
      }, 3000);

      console.log(elements)
    },
    editSum(sum) {
      var value = (Number(sum)).toLocaleString("en-US", {
        style: "currency",
        currency: "EUR",
      })
      var str = new String(value);
      str = str
        .replace(/,/g, "__COMMA__") // Replace `,` by some unique string
        .replace(/\./g, ',')         // Replace `.` by `,`
        .replace(/__COMMA__/g, '.'); // Replace the string by `.`
      str = str.replace(/€/g, '');
      str = str + ' €'
      return str
    },
    toFixed(val) {
      return Math.floor(val * 100) / 100
    },
    async downloadPdf() {
      this.$refs.html2PdfDownload.generatePdf();
    },

    async previewPdf() {
      this.$refs.html2PdfPreview.generatePdf();
    },

    copyLink() {
      var copyText = document.getElementById("myInput");
      copyText.select();
      document.execCommand("copy");
      //alert("Copied the link: " + copyText.value);
    },

    nameChangeFirst(name) {
      let arr = name.split(',')
      return arr[0]
    },
    nameChangeLast(name) {
      let arr = name.split(',')
      return arr[1]
    },
    getImgUrl(pet) {
      var images = require.context('../assets/styles/flats/', false, /\.jpg$/)
      return images('./' + pet + ".jpg")
    }
  },
  mounted() {
    this.imgPreUrl = this.msg.roomNumber.number + '-' + this.msg.style.displayStyle + '.jpg'

    this.color = this.$store.getters.color
    this.colorWohnen = this.$store.getters.colorWohnen
    this.arrSelect = this.$store.getters.selectConfigurator


    if (this.msg.style.displayStyle == 'PURE') {
      this.color = 'color:silver'
      this.colorWohnen = 'color:silver'
      this.arrSelect = [{ subLineItemsName: "Bauseitige Möbel", productID: "77958" }, { subLineItemsName: "Waschtischarmatur", productID: "75002" }, { subLineItemsName: "Waschtisch mit Becken", productID: "77902" }, { subLineItemsName: "Wandfarbe", productID: "77875" }, { subLineItemsName: "Parkett", productID: "77872" }, { subLineItemsName: "Türbeschlag", productID: "77882" }]
    }


    this.verbauteDesignlinie = 0
    this.gesamtpreis = 0
    this.gesamt = 0

    this.href = window.location
    this.link = String(this.href).replace('design', 'view')

    if (this.msg.style.displayStyle == 'PURE_BASIC') {
      for (let i = 0; i < this.arrSelect.length; i++) {
        if (this.arrSelect[i].subLineItemsName == 'Waschtisch mit Becken') {
          let p = this.arrSelect[i].productID
          if (p == '77905')
            this.grosformat = '77899'
          if (p == '77903')
            this.grosformat = '74998'
          if (p == '77904')
            this.grosformat = '77900'
          if (p == '77905')
            this.grosformat = '77901'
        }
      }
    }
    if (this.msg.style.displayStyle == 'PURE') {
      this.grosformat = 'none'
    }



    if (this.msg.style.displayStyle == 'SILK') {
      axios.get('https://us-central1-cadman-63dc7.cloudfunctions.net/getRoomBookByStyleAndFlat?unitId=' + /*10678*/this.msg.flatID.id + '&style=silk')
        .then((response) => {
          this.price = response.data.list
          this.error = response.data
        })
        .finally(() => {
          if (this.error != 'empty') {
            this.verbauteDesignlinie = this.price["Verbaute Designlinie"][0][0].subLineItems[0].productGroup.productOptions[0].productPrices[0].priceInCents
            this.gesamtpreis = this.price["totalPrice"]
            this.gesamt = this.gesamtpreis + this.verbauteDesignlinie
            this.startLoader = false

            /*
            if (this.user.loggedIn && this.user.data.displayName != 'Superadmin') {
              var url = "https://us-central1-cadman-63dc7.cloudfunctions.net/returnSelecedItemsForAuthUsers";
              var xhr = new XMLHttpRequest();
              xhr.open("POST", url);
              xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
              xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                  console.log(xhr.status);
                  console.log(xhr.responseText);
                }
              };
              var data = "selectiosList=" + JSON.stringify([]) + "&localStorage=" + localStorage.getItem('crown') + "&style=" + "SILK" + "&configurator=" + "none" + "&unitId=" + localStorage.getItem('crownBayerFlatId');
              xhr.send(data);
            }
            */
            this.changeColor(this.msg.style.displayStyle)
            //this.addItem()
          }
          else {
            this.startLoader = false
          }
        })
    }

    if (this.msg.style.displayStyle == 'COCO') {
      axios.get('https://us-central1-cadman-63dc7.cloudfunctions.net/getRoomBookByStyleAndFlat?unitId=' + /*10678*/this.msg.flatID.id + '&style=coco')
        .then((response) => {
          this.price = response.data.list
          this.error = response.data
        })
        .finally(() => {
          if (this.error != 'empty') {
            this.verbauteDesignlinie = this.price["Verbaute Designlinie"][0][0].subLineItems[0].productGroup.productOptions[0].productPrices[0].priceInCents
            this.gesamtpreis = this.price["totalPrice"]
            this.gesamt = this.gesamtpreis + this.verbauteDesignlinie
            this.startLoader = false

            /*
            if (this.user.loggedIn && this.user.data.displayName != 'Superadmin') {
              var url = "https://us-central1-cadman-63dc7.cloudfunctions.net/returnSelecedItemsForAuthUsers";
              var xhr = new XMLHttpRequest();
              xhr.open("POST", url);
              xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
              xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                  console.log(xhr.status);
                  console.log(xhr.responseText);
                }
              };
              var data = "selectiosList=" + JSON.stringify([]) + "&localStorage=" + localStorage.getItem('crown') + "&style=" + "COCO" + "&configurator=" + "none" + "&unitId=" + localStorage.getItem('crownBayerFlatId');
              xhr.send(data);
            }
            */
            this.changeColor(this.msg.style.displayStyle)
            //this.addItem()
          }
          else {
            this.startLoader = false
          }
        })
    }

    if (this.msg.style.displayStyle == 'PURE') {
      axios.get('https://us-central1-cadman-63dc7.cloudfunctions.net/getRoomBookByStyleAndFlat?unitId=' + /*10678*/this.msg.flatID.id + '&style=pure_basic')
        .then((response) => {
          this.price = response.data.list
          this.error = response.data
        })
        .finally(() => {
          if (this.error != 'empty') {
            this.verbauteDesignlinie = this.price["Verbaute Designlinie"][0][0].subLineItems[0].productGroup.productOptions[0].productPrices[0].priceInCents
            this.gesamtpreis = this.price["totalPrice"]
            //this.kuche = this.price["Verbaute Designlinie"][0][0].subLineItems[0].productGroup.productPrices[1].priceInCents
            this.changeColor(this.msg.style.displayStyle)
            this.startLoader = false
          }
          else {
            this.startLoader = false
          }
        })
    }

    if (this.msg.style.displayStyle == 'PURE_BASIC') {
      axios.get('https://us-central1-cadman-63dc7.cloudfunctions.net/getRoomBookByStyleAndFlat?unitId=' + /*10678*/this.msg.flatID.id + '&style=pure')
        .then((response) => {
          this.price = response.data.list
          this.error = response.data
        })
        .finally(() => {
          if (this.error != 'empty') {
            this.verbauteDesignlinie = this.price["Verbaute Designlinie"][0][0].subLineItems[0].productGroup.productOptions[0].productPrices[0].priceInCents
            this.gesamtpreis = this.price["totalPrice"]
            this.kuche = this.price["Verbaute Designlinie"][0][0].subLineItems[0].productGroup.productOptions[1].productPrices[0].priceInCents
            this.changeColor(this.msg.style.displayStyle)
            this.startLoader = false
          }
          else {
            this.startLoader = false
          }
        })
    }

  }
}
</script>

<style scope>
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  top: 0;
}

.zusammenfassung {
  position: relative;
  /*height: 60vh;*/
  overflow-y: overlay;
}

.menu-zusammenfassung {
  background: none;
}

.menu-zusammenfassung .crown__rooms--menu button {
  color: inherit !important;
  /*text-shadow: 1px 1px 1px #c7c7c7;*/
}

/*
.menu-zusammenfassung .crown__rooms--menu button.active {
  border-bottom: 1px solid #323A41 !important;
}
*/

.zusammenfassung__info {
  position: absolute;
  width: 100%;
}

.zusammenfassung__info h2 {
  text-align: center;
}
.outerContainerZu {
  width: 100%;
  height: auto;
  overflow: auto;
  display: flex;
  position: relative;
}
.formContainerZusammen {
  position: relative;
  width: 100%;
  max-width: 25em;
  height: auto;
  display: block;
  margin: auto;
  color: #fff;
}
.formContainerZusammen .formHeadline {
  margin-bottom: 2em;
}
.formContainerZusammen .formHeadline p {
  margin: 0;
  padding: 0;
}
.formContainerZusammen .formHeadline p.headline {
  font-size: 1.875em;
  padding-bottom: 0.2em;
}
.form .formInput {
  width: 100%;
  height: auto;
  padding: 0.9em 0;
  margin: 0;
  display: flex;
  position: relative;
  white-space: pre-line;
}
.form .formInput input,
.form .formInput textarea {
  display: block;
  width: 100%;
  height: 2.4em;
  line-height: 2.4em;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1.25em;
  border: 1px solid #fff;
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 0;
  outline: none;
  position: relative;
  color: #fff;
}
.form .formInput input.error {
  border-color: #ffc4c4;
}
.form .formInput textarea {
  height: 13em;
}
.form .formInput input:focus,
.form .formInput textarea:focus {
  color: #fff;
}
.form .formInput.formButton input {
  border: 0;
  background-color: #fff;
  color: #323a41;
  cursor: pointer;
}
.form .formInput.simpleButton {
  padding: 0;
  margin: 0;
  width: auto;
  height: auto;
  line-height: normal;
  display: inline-block;
  white-space: normal;
}
.form .formInput.simpleButton input {
  line-height: normal;
  width: auto;
  height: auto;
  display: inline;
  border: 0;
  color: #fff;
  padding: 0;
  margin: 0;
  text-decoration: underline;
  cursor: pointer;
}
.error::placeholder {
  color: #ffc4c4;
}
::placeholder {
  color: #fff;
}
.form input[type="button"] {
  margin-top: 0.9em;
}
.form .formInput.disabledInput {
  opacity: 0.6;
}
.form .formInput.disabledInput .loginPasswordView {
  z-index: -1;
}
.form .formInput .loginPasswordView {
  font-size: 0.8em;
  width: 3em;
  text-align: center;
  height: 2.4em;
  line-height: 2.4em;
  position: absolute;
  top: 1.25em;
  right: 0;
  cursor: pointer;
}
.form .formInput .loginPasswordView:before {
  content: "\e9ce";
}
.form .formInput .loginPasswordView.showPassword:before {
  content: "\e9d1";
}
.tableContainer {
  height: auto;
  margin: 0 auto;
}
.tableContainer .tableHeadline {
  text-align: center;
  margin-bottom: 3em;
  z-index: 1;
  position: relative;
}
.tableContainer .tableHeadline .userInfo {
  font-size: 1.875em;
  padding: 0 0 0.2em 0;
  font-weight: normal;
  margin-top: 0;
}
.tableContainer .tableHeadline .userApartment {
  margin: 0;
  padding: 0;
}
.tableContainer .tableHeadline .userApartment a {
  color: #fff;
  font-size: 1em;
  text-transform: uppercase;
  outline: none;
  text-decoration: none;
  display: inline-block;
  margin: 0 0.35em;
}
.tableContainer .tableHeadline .userApartment a:hover,
.tableContainer .tableHeadline .userApartment a.active {
  text-decoration: underline;
}
.tableContainer .apartmentInfo {
  width: 100%;
  border-spacing: inherit;
}
.tableContainer .apartmentInfo.apartmentHead {
  margin-bottom: 1em;
  width: 80%;
  margin: 0 auto;
  padding-bottom: 1em;
}
.tableContainer .apartmentInfo.apartmentType {
  width: 80%;
  margin: 0 auto;
  padding: 0.8em 0;
  border-top: 1px solid #323a41;
  box-sizing: border-box;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
.tableContainer .apartmentInfo.apartmentType.last {
  border-bottom: 1px solid #323a41;
}
.tableContainer .apartmentInfo thead {
  text-align: left;
}
.tableContainer .apartmentInfo thead tr th {
  font-weight: normal;
}
.tableContainer .apartmentInfo thead .tablePos,
.tableContainer .apartmentInfo tbody .apartmentName,
.tableContainer .apartmentInfo tbody .tablePos {
  padding-left: 2em;
  width: 12%;
  padding-right: 1em;
  box-sizing: border-box;
}
.tableContainer .apartmentInfo.apartmentType tbody .apartmentName {
  box-sizing: border-box;
  text-align: left;
  position: relative;
  cursor: pointer;
}
.tableContainer .apartmentInfo.apartmentType tbody .apartmentName:after {
  content: "\25BC";
  font-family: "icomoon" !important;
  position: absolute;
  margin-left: 0.4em;
  margin-top: 0.45em;
  font-size: 0.6em;
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
.tableContainer .apartmentInfo.apartmentType.active tbody .apartmentName:after {
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.tableContainer .apartmentInfo.apartmentType .apartmentList {
  display: table-column;
}
.tableContainer .apartmentInfo.apartmentType.active .apartmentList {
  display: table-row;
}
.tableContainer .apartmentInfo thead .tableChanges,
.tableContainer .apartmentInfo tbody .tableChanges {
  width: 25%;
  padding-right: 1em;
  box-sizing: border-box;
}
.tableContainer .apartmentInfo thead .tableDate,
.tableContainer .apartmentInfo tbody .tableDate {
  width: 10%;
  padding-right: 1em;
  box-sizing: border-box;
}
.tableContainer .apartmentInfo thead .tableStatus,
.tableContainer .apartmentInfo tbody .tableStatus {
  width: 10%;
  padding-right: 1em;
  box-sizing: border-box;
}
.tableContainer .apartmentInfo thead .tablePrice,
.tableContainer .apartmentInfo tbody .tablePrice {
  width: 12%;
  padding-right: 1em;
  box-sizing: border-box;
}
.tableContainer .apartmentInfo thead .tableLink,
.tableContainer .apartmentInfo tbody .apartmentLink,
.tableContainer .apartmentInfo tbody .tableLink {
  padding-right: 2em;
  width: 18%;
  box-sizing: border-box;
  text-align: right;
}
.tableContainer .apartmentInfo.apartmentType tbody .apartmentLink {
  box-sizing: border-box;
  text-align: right;
}
.tableContainer .apartmentInfo.apartmentType tbody .apartmentLink a {
  font-size: 0.9em;
  color: inherit;
}
.tableContainer .apartmentInfo.apartmentType .apartmentList td {
  padding-top: 0.5em;
}
.tableContainer .form .formInput input {
  width: 20%;
}
.table-no-info {
  text-align: center;
}

.wrapper {
  display: flex;
  justify-content: center;
}

details {
  width: 100%;
}

summary {
  position: relative;
  cursor: pointer;
  background: #c6c0bf;
}

summary h4 {
  display: inline;
}

.crown__rooms--block {
  margin-top: 4em;
  position: relative;
  width: 100%;
  bottom: 35px;
  z-index: 2;
  left: 0;
}
.crown__rooms--block .crown__room--style {
  display: flex;
  justify-content: center;
  bottom: 2em;
  margin: 0 auto;
  width: 100%;
  left: 0;
}
.crown__room--style .crown__rooms--room {
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  bottom: 2em;
  width: 1178px;
  height: 35px;
  justify-content: space-between;
  font-family: "35-FTR", sans-serif;
  font-size: 15px;
  letter-spacing: 0.06rem;
  color: inherit;
  box-sizing: border-box;
  padding-left: 2em;
  padding-right: 2em;
  align-items: center;
  justify-content: space-between;
}
.crown__rooms--style-info {
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: #ccc;
  display: flex;
  width: 1178px;
  font-family: "35-FTR", sans-serif;
  font-size: 0.8rem;
  letter-spacing: 0.06rem;
  margin: 0 auto;
  background-color: rgba(238, 237, 236, 0.6);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  box-sizing: border-box;
}
.header-padding {
  padding: 2em;
  display: flex;
}
.header-padding .col {
  flex: 1;
  display: inline-block;
}
.header-padding .col:hover {
  color: #fff;
}
.header-padding .col a {
  color: inherit;
}
.pdf {
  width: 800px;
  background: #fff;
  color: #323a41;
}
/*.tableQuantity {*/
/*  text-align: center;*/
/*  padding-right: 1em;*/
/*}*/
/*.tablePrice {*/
/*  text-align: right;*/
/*}*/
.footer-more {
  cursor: pointer;
}
.img__price--style {
  width: 1178px;
}

.img__price--style img {
  width: 100%;
}

.coco {
  background-color: #323a41 !important;
  color: #fff !important;
}

.pure {
  background-color: #b4afae !important;
  color: #fff !important;
}

.zusammenfassung-coco p {
  color: #fff;
}

.zusammenfassung-coco {
  color: #fff;
}

.link-input {
  position: fixed;
  top: -3000px;
  left: -3000px;
}

.none-z {
  display: none;
}

.price {
  width: 1178px;
  margin: 0 auto;
  text-align: center;
}

.price h2 {
  font-family: "35-FTR", sans-serif;
  font-weight: 300;
  font-style: normal;
  padding: 0.4em;
  padding-bottom: 0.4em;
  color: #fff;
  text-transform: uppercase;
  font-size: 20px;
}

.price__info {
  margin-bottom: 1em;
  height: 100px;
  display: grid;
  grid-template-columns: 100px 3fr 2fr 1fr 1fr;
}

.price__info--total {
  display: grid;
  grid-template-columns: auto 20%;
  padding-right: 2em;
  padding-left: 2em;
}

.price__info div {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  /*padding: 1em;*/
}

.price__info--item-2 {
  grid-column-start: 2;
  padding-left: 2em;
  padding-right: 2em;
}

.price__header--room {
  margin-bottom: 1em;
}

.price__info--img img {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.total-price {
  text-align: right;
}

.none {
  display: none;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.sticky {
  height: 50px;
  width: 80%;
  margin: 0 auto;
  background-color: rgba(238, 237, 236, 0.6);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  box-sizing: border-box;
  position: sticky;
  top: 0px;
  z-index: 1;
}

.stiky__header {
  display: grid;
  grid-template-columns: 25% 30% 25% 10% 10%;
  font-weight: 600;
}

.stiky__header--item {
  text-align: center;
  top: 12px;
  position: relative;
}

details > summary::-webkit-details-marker {
  display: none;
}

details summary {
  list-style-type: none;
} /* Firefox */
details summary::marker {
  display: none;
}

.price__sum {
  background: #fff;
  border: 1px solid #707070;
  letter-spacing: 0.05em;
}

.price__sum .price h2 {
  color: #323a41 !important;
  text-align: right;
  padding: 0;
  letter-spacing: 0.1em;
}

.price__sum--header {
  border-bottom: 1px solid #707070;
  font-size: 15px;
  height: 35px;
  position: relative;
  padding-left: 1em;
}
.price__sum--header p {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0;
  font-weight: bold;
  margin-block-start: 0;
  margin-block-end: 0;
}
.gesamtsumme h2 {
  font-weight: bold;
}

.zusammenfassung-coco .price__sum--header p {
  color: #323a41;
}

.price__header--block {
  display: flex;
  justify-content: space-between;
  height: 35px;
  width: 1178px;
  background: #fff;
  margin-bottom: 2em;
}
.price__header--block-item {
  width: 100%;
  text-align: center;
  position: relative;
}

.price__header--block-item p {
  position: absolute;
  top: 50%;
  -webkit-margin-before: 0;
  margin-block-start: 0;
  -webkit-margin-after: 0;
  margin-block-end: 0;
  letter-spacing: 0.05em;
  transform: translate(0, -50%);
  margin: 0 auto;
  width: 100%;
}
.price__header--block-item p.bold {
  font-weight: bold;
}

details summary::before {
  position: absolute;
  right: 1em;
  top: 11px;
  content: "▲";
  color: #fff;
  /* you can style it however you want, use background-image for example */
}

/* By using [open] we can define different styles when the disclosure widget is open */
details[open] summary::before {
  content: "▼";
  color: #fff;
}

/*
details[open] summary ~ * {
  animation: sweep 0.5s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-left: 0px;
  }
  100% {
    opacity: 1;
    margin-left: 0px;
  }
}
*/

.price__info--img {
  background: #fff;
}

.price__header--block-item p {
  cursor: pointer;
}

.zusammenfassung-coco .img__price--style p {
  color: #000;
}

.zusammenfassung-coco .price__header--block-item p {
  color: #000;
}

.scroll .back__flats {
  position: relative;
  width: 100%;
  top: -179px;
  margin-left: 1em;
}

.pdf-content {
  font-family: Arial, sans-serif !important;
  width: 800px;
  position: relative;
  color: #000000;
}

.pdf-content details summary::marker {
  display: none;
}

.pdf-content details summary::before {
  position: absolute;
  right: 1em;
  top: 11px;
  content: "";
  color: #fff;
  /* you can style it however you want, use background-image for example */
}

/* By using [open] we can define different styles when the disclosure widget is open */
.pdf-content details[open] summary::before {
  content: "";
  color: #fff;
}

.head__pdf {
  margin-left: 1em;
  padding-bottom: 1em;
  margin-right: 1em;
  padding-right: 1em;
  margin-top: 2em;
  margin-bottom: 1em;
  border-bottom: 1px solid #000;
  text-align: center;
}

.price__info--pdf {
  display: grid;
  grid-template-columns: 0fr 3fr 2fr 1fr 1fr;
}

.break {
  page-break-before: avoid;
}

.price__info--pdf div {
  padding-bottom: 0.4em;
  padding-top: 0.4em;
  font-size: 8pt;
}

.price__info--pdf span {
  font-size: 8pt;
}

.price__info--pdf .price__info--item-2 {
  font-family: Arial, sans-serif !important;
  color: #000000;
  padding-left: 4em;
  padding-right: 0em;
}

.price__info--pdf .price__info--item-4,
.price__info--pdf .price__info--item-5 {
  text-align: right;
  padding-right: 4em;
}

.price__info--item-5 {
  text-align: right;
}

.price__info--pdf .price__info--item-3 {
  text-align: center;
}

.price__sum {
  background: #fff;
  border: 0px solid #fff;
  margin-top: 5em;
}

.price__sum.pdf .price {
  width: 800px;
}
.price__sum.pdf .price__sum {
  width: 800px;
}
.price__sum.pdf .price__info--total {
  font-family: Arial, sans-serif !important;
  font-size: 12pt;
  color: #2f3137;
  display: grid;
  grid-template-columns: auto 25%;
  padding-right: 40px;
  padding-left: 40px;
}

.select-pdf {
  width: 800px;
}

.document__pdf {
  font-family: Arial, sans-serif !important;
  font-size: 8pt;
  color: #2f3137;
  width: 800px;
  display: grid;
  grid-template-columns: 3fr 2fr 1fr 1fr;
  margin: 0 auto;
}

.select-pdf .header {
  font-family: Arial, sans-serif !important;
  font-size: 8pt;
  color: #2f3137;
  text-align: center;
  margin-bottom: 30px;
}

.select-pdf .header img {
  margin-bottom: 40px;
}

.document__pdf div {
  text-align: center;
  padding-bottom: 0.5em;
  padding-top: 0.5em;
}

.document__pdf .sum {
  text-align: right;
  padding-right: 40px;
}

.document__pdf .name {
  padding-left: 40px;
  text-align: left;
}

.header__pdf {
  font-family: Arial, sans-serif !important;
  font-size: 16pt;
  color: #2f3137;
  text-align: center;
  border-bottom: 1px solid #2f3137;
  padding-bottom: 20px;
  width: 720px;
  margin: 0 auto;
  margin-bottom: 20px;
  padding-top: 40px;
}

.select-pdf .info {
  font-family: Arial, sans-serif !important;
  font-size: 6pt;
  color: #2f3137;
  text-align: center;
  padding-right: 40px;
  padding-left: 40px;
  padding-top: 100px;
}

.select-pdf .styles {
  margin-top: 20px;
  height: 400px;
}

.select-pdf .styles.flats {
  width: 650px;
  height: auto;
}

.price__info--img.none {
  display: none;
}

.moodboard__margin {
  margin-bottom: 1em;
}

.moodboard__margin--img {
  margin-bottom: 1em;
}

.display__none {
  display: none;
}
</style>