<template>
  <div class="products__info">
    <div v-if="showSelect == false && msg.action == 0">
      <h3>
        Wir bedanken uns für Ihre wundervolle Wahl! Ihre Anfrage wurde zu
        Zusammenfassung hinzugefügt
      </h3>
    </div>
    <div
      v-if="
        showSelect == true &&
        this.productGroup.portalOffer == false &&
        msg.action == 0
      "
    >
      <h3>Angebotsportal geschlossen</h3>
    </div>
    <div v-if="showSelect == true && this.productGroup.portalOffer == true">
      <div class="loader-product" v-if="!loader">
        <img
          src="http://garmonia.site/_garmonia/temp/crown/loader.gif"
          alt=""
        />
      </div>
      <div v-for="(p, index) in arr" :key="index">
        <div v-if="index == productShow && loader" class="product__info">
          <div
            v-for="(img, index) in p.product.images"
            :key="index"
            class="product__info--img"
          >
            <img :src="img.thumbUrl" alt="" />
          </div>
          <div>
            <p class="product__name">{{ p.product.name }}</p>
          </div>
          <!--<h3 v-if="p.priceInCents !== 0">Price: {{ p.priceInCents }}</h3>-->
          <div class="products__info--button-group" v-if="!msg.onlySee">
            <!-- Test v1 -->
            <button
              v-if="index > 0"
              v-on:click="
                picked = arr[index - 1].product.id;
                prev();
                selectItemInfo();
              "
            >
              &#9668;
            </button>
            <button
              class="select"
              v-on:click="
                select(
                  msg.flatID.id,
                  msg.room.name,
                  msg.roomBook.id,
                  msg.lineItems.id,
                  msg.subLineItems.id,
                  productGroup,
                  p.id,
                  p.product.id,
                  p.product.name,
                  p.priceInCents,
                  msg.defaultQuantity.quantity
                )
              "
            >
              Auswählen
            </button>
            <button
              v-if="index < arr.length - 1"
              v-on:click="
                picked = arr[index + 1].product.id;
                next(arr.length - 1);
                selectItemInfo();
              "
            >
              &#9658;
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from 'axios';
import moment from 'moment'
const query = 'https://us-central1-cadman-63dc7.cloudfunctions.net/returnBayerSelections?localStorage='


export default {
  data: function () {
    return {
      loader: false,
      selectItems: {},
      showSelect: true,
      picked: '',
      pickedArea: '',
      selectItem: '',
      pickedDelete: '',
      productShow: 0,
      productGroup: {},
      selectProduct: [],
      moment: '',
      styleSelect: '',
      arr: []
    }
  },
  name: 'ProductsInfo',
  props: {
    userLink: {},
    msg: {
      flatID: {},
      roomBook: {},
      lineItems: {},
      subLineItems: {},
      room: {},
      defaultQuantity: {},
      productGroup: {},
      style: {},
      action: {},
      onlySee: {}
    }
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user"
    })
  },
  mounted() {
    if (this.msg.style.action == 'PURE')
      this.styleSelect = "design_line_crown:pure"
    if (this.msg.style.action == 'SILK')
      this.styleSelect = "design_line_crown:silk"
    if (this.msg.style.action == 'COCO')
      this.styleSelect = "design_line_crown:coco"
    this.productGroup = this.msg.productGroup
    for (let key in this.productGroup.productPrices) {
      let arr = this.productGroup.productPrices[key].tags
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] == this.styleSelect)
          this.arr.push(this.productGroup.productPrices[key])
      }
    }
    var urlQuery = query + this.userLink
    axios.get(urlQuery)
      .then((response) => {
        this.selectItems = response.data
      })
      .finally(() => {
        for (let key_select in this.selectItems) {
          for (let key_arr in this.arr) {
            if (this.selectItems[key_select].productPriceId == this.arr[key_arr].id && this.arr.length > 1) {
              this.arr.unshift(...this.arr.splice(key_arr, 1));
            }
          }
        }
        this.loader = true
      })
    for (let key in this.productGroup.productPrices) {
      this.selectProduct.push({ id: this.productGroup.productPrices[key].id, select: false })
    }
  },
  methods: {
    changeProduct(product) {
      this.$store.dispatch("ADD_PRODUCT", product);
    },
    selectItemInfo() {
      for (let key in this.$store.getters.products) {
        if (this.$store.getters.products[key].id == this.picked)
          this.selectItem = this.$store.getters.products[key].actions
      }
    },
    prev() {
      if (this.productShow > 0) {
        this.productShow--
        //this.$store.dispatch("ADD_PRODUCT", product);
        //this.$store.dispatch("CHANGE_PICKED", delPicked)
      }
    },
    next(length) {
      if (this.productShow == length) {
        this.productShow = length
      }
      if (this.productShow < length) {
        this.productShow++
        //this.$store.dispatch("ADD_PRODUCT", product);
        //this.$store.dispatch("CHANGE_PICKED", delPicked)
      }
    },
    select(flatID, room, roomBook, lineItems, subLineItems, productGroup, productPrics, id, name, price, quantity /* pickedArea */) {

      /*
      for (let key in this.selectProduct) {
        this.selectProduct[key].select = false
      }
      for (let key in this.selectProduct) {
        if (this.selectProduct[key].id == productPrics)
          this.selectProduct[key].select = true
      }
      */

      if (this.user.loggedIn == false) {
        let query =
          'https://us-central1-cadman-63dc7.cloudfunctions.net/returnSelecedItems?' +
          'localStorage=' + localStorage.getItem('crown') +
          '&flatId=' + flatID +
          '&room=' + room +
          '&datatime=' + moment().unix() +
          '&productGroup=' + productGroup.id +
          '&name=' + name +
          '&priceInCents=' + price +
          '&defaultQuantity=' + quantity +
          '&productId=' + id +
          '&subLineItems=' + subLineItems +
          '&lineItems=' + lineItems +
          '&productPriceId=' + productPrics
        axios.get(query)
          .catch(err => {
            // what now?
            alert('Error ' + err)
          })
          .finally(() => {
            this.showSelect = false
          })
      }

      if (this.user.loggedIn == true) {
        let queryBayerId = 'https://us-central1-cadman-63dc7.cloudfunctions.net/returnSelecedItemsForAuthUsers?' +
          'localStorage=' + localStorage.getItem('crown') +
          '&flatId=' + flatID +
          '&room=' + room +
          '&datatime=' + moment().unix() +
          '&productGroup=' + productGroup.id +
          '&name=' + name +
          '&priceInCents=' + price +
          '&defaultQuantity=' + quantity +
          '&productId=' + id +
          '&subLineItems=' + subLineItems +
          '&lineItems=' + lineItems +
          '&productPriceId=' + productPrics +
          '&bayerId=' + localStorage.getItem("crownBayerId")
        axios.get(queryBayerId)
          .catch(err => {
            // what now?
            alert('Error ' + err)
          })
          .finally(() => {
            this.showSelect = false
          })
      }
    }
  }
}
</script>

<style scope>
.product__info {
  display: flex;
  flex-direction: column;
  height: 400px;
  justify-content: space-between;
}
.product__info--img {
  display: flex;
  padding: 2em;
  height: 200px;
}
.product__info--img img {
  margin: 0 auto;
}
.product__name {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.products__info--button-group {
  display: flex;
  justify-content: center;
}
.products__info--button-group button {
  margin: 1em;
  border: 0;
  padding: 0.5em;
  background: #fff;
}
.products__info--button-group button.select {
  border: 1px solid #323A41;
  padding-left: 1em;
  padding-right: 1em;
}
.products__info--button-group button:hover.select {
  border: 1px solid #ad8059;
  background: #ad8059;
  padding-left: 1em;
  padding-right: 1em;
}
.add__moodboard {
  text-align: center;
}
.loader-product {
  padding-top: 5em;
  width: 30%;
  margin: 0 auto;
}
.loader-product img {
  width: 100%;
  margin: 0 auto;
}
</style>