<template>
  <div class="moodboard">
    <div v-if="loader == false">Laden...</div>

    <div v-if="loader == true">
      <div v-for="(p, i) in configuratorData" :key="i">
        <div v-if="visiblePersonal == p.header">
          <div class="moodboard__check--header">

            <p
              class="menu__personal--item"
              v-if="p.header == 'Malerarbeiten Wände'"
            >
              Wandfarben
            </p>
            <p
              class="menu__personal--item"
              v-if="p.header == 'Parkettarbeiten'"
            >
              Boden <br />
              Mehrschichtparkett in Dielenoptick <br />
              Eiche geölt
            </p>
            <p
              class="menu__personal--item"
              v-if="p.header == 'Türdrückergarnituren'"
            >
              Ausstattung
            </p>


            <p
              class="menu__personal--item"
              v-if="p.header == 'Fliesenarbeiten Waschtisch'"
            >
              Fliesenfarben
            </p>
            <p
              class="menu__personal--item"
              v-if="p.header == 'Waschtischunterschrank'"
            >
              Möbelfronten
            </p>
            <p
              class="menu__personal--item"
              v-if="p.header == 'Waschtischanlage'"
            >
              Armaturen
            </p>

            <!--
            <p class="menu__personal--item" v-if="msg.room.name == 'Bad'">
              {{ p.header }}
            </p>
            -->
          </div>
          <div class="moodboard__check--panel">
            <div
              v-for="(product, productIndex) in p.productPrices"
              :key="productIndex"
              class="moodboard__check"
            >
              <div
                class="moodboard__check--item pointer"
                :class="
                  product.tags[0] != 'design_line_crown:pure' ? 'none' : ''
                "
                v-on:click="
                  addLink(p.header, product.product.id);
                  select();
                "
              >
                <div
                  v-for="(img, item) in product.product.images[0]"
                  :key="item"
                  class="moodboard__info--img"
                  :class="p.header == 'Türdrückergarnituren' ? 'turd' : ''"
                >
                  <img :src="img" alt="" class="round" />
                </div>
                <div class="moodboard__check--item-text">
                  <div
                    v-if="
                      msg.room.name == 'Wohnen' && p.header == 'Parkettarbeiten'
                    "
                  >
                    {{ nameChangeLast(product.product.name) }}
                    <!--{{ product.product.name }}-->
                  </div>
                  <div
                    v-if="
                      msg.room.name == 'Wohnen' && p.header != 'Parkettarbeiten'
                    "
                  >
                    <!--{{ nameChangeLast(product.product.name) }}-->
                    {{ product.product.name }}
                  </div>
                  <div v-if="msg.room.name == 'Bad'">
                    <!--{{ nameChangeThree(product.product.name) }}-->
                    {{ product.product.name }}
                  </div>
                  <!--{{ product.product.name }} <br />-->
                  <!--{{ nameChangeFirst(product.product.name) }} <br />-->
                  <!--{{ nameChangeLast(product.product.name) }} <br />-->
                  <!--{{ product.priceInCents }} -->
                  <!--{{ product.product.id }}-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="personalisiren">
        <div>
          <p>{{ msg.room.name }} personalisieren</p>
        </div>
        <div class="menu__personal">
          <div v-for="(p, i) in configuratorData" :key="i" class="pointer">
            <div
              v-if="
                (p.header == 'Fliesenarbeiten Waschtisch' &&
                  msg.room.name != 'Wohnen') ||
                (p.header == 'Waschtischunterschrank' &&
                  msg.room.name != 'Wohnen') ||
                (p.header == 'Waschtischanlage' && msg.room.name != 'Wohnen') ||
                (p.header == 'Malerarbeiten Wände' && msg.room.name != 'Bad') ||
                (p.header == 'Parkettarbeiten' && msg.room.name != 'Bad') ||
                (p.header == 'Türdrückergarnituren' && msg.room.name != 'Bad')
              "
              v-on:click="visiblePersonal = p.header"
            >
              <p
                class="menu__personal--item"
                v-if="p.header == 'Malerarbeiten Wände'"
              >
                Wandfarbe
              </p>
              <p
                class="menu__personal--item"
                v-if="p.header == 'Parkettarbeiten'"
              >
                Parkett
              </p>
              <p
                class="menu__personal--item"
                v-if="p.header == 'Türdrückergarnituren'"
              >
                Ausstattung
              </p>

              <p
                class="menu__personal--item"
                v-if="p.header == 'Fliesenarbeiten Waschtisch'"
              >
                Fliesenfarben
              </p>
              <p
                class="menu__personal--item"
                v-if="p.header == 'Waschtischunterschrank'"
              >
                Möbelfronten
              </p>
              <p
                class="menu__personal--item"
                v-if="p.header == 'Waschtischanlage'"
              >
                Armaturen
              </p>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment'
import BAD_CONFIG from './../bd/BadConfig.json'
import WOHNEN_CONFIG from './../bd/WohnenConfig.json'
//const url = 'https://cadman-63dc7.firebaseio.com/AdditionalConfig/.json';
const urlIMG = 'https://cadman-63dc7.firebaseio.com/PairsImage/.json';
export default {
  data: function () {
    return {
      infoRoomBook: {},
      moodboard: {},
      img: '',
      floors_temp: [],
      walls_temp: [],
      floors: [],
      walls: [],
      pickedFloor: '',
      pickedWall: '',
      dataIMG: [],
      moodboardIMG: '',
      activeFloors: [],
      activeWalls: [],
      temp: [],
      count: 0,
      loader: false,

      configuratorData: [],
      visiblePersonal: '',
      configuratorSelect: [],
      arrIMG: [],
      link: '',
      imgData: [],
      srcLink: '',
      activeItem: ''
    };
  },
  name: 'Moodboard',
  props: {
    msg: {
      info: {},
      flat: '',
      room: {},
      start: ''
    }
  },
  mounted() {
    let temp = []
    let arr = []

    if (this.msg.room.name == 'Bad') {
      temp = BAD_CONFIG
      /*
      axios.get('https://us-central1-cadman-63dc7.cloudfunctions.net/getRoomBookTest?configuratorId=f4990e95-ed37-40c3-82fb-d226b13eabf3')
        .then((result) => {
          temp = result.data
        })
        .finally(() => {
      */
      for (let i = 0; i < temp.length; i++)
        for (let j = 0; j < temp[i].length; j++)
          arr.push(temp[i][j])

      for (let key in arr) {
        if (arr[key].id == this.msg.start) {
          arr.unshift(...arr.splice(key, 1));
        }
      }
      let arrBad = arr
      for (let j in arrBad) {
        for (let key = 0; key < this.msg.info.lineItems.length; key++) {
          if (this.msg.info.lineItems[key].subLineItems[0].productGroup == null) {
            console.log(null)
          }
          else {
            let arr = []
            if (this.msg.info.lineItems[key].id == arrBad[j].id && this.msg.info.lineItems[key].title != 'Fliesenarbeiten Wände' && this.msg.info.lineItems[key].title != 'Fliesenarbeiten Boden') {
              arr = this.msg.info.lineItems[key].subLineItems[0].productGroup.productPrices
              this.configuratorData.push({ header: this.msg.info.lineItems[key].title, productPrices: arr })
            }
          }
        }
      }
      this.configuratorSelect = this.$store.getters.configurator.bad.select
      if (this.configuratorSelect.length == 0) {
        for (let key in this.configuratorData) {
          if (this.configuratorData[key].header == 'Fliesenarbeiten Waschtisch')
            this.configuratorSelect.push({ header: this.configuratorData[key].header, id: this.configuratorData[key].productPrices[0].product.id })
          if (this.configuratorData[key].header == 'Waschtischunterschrank')
            this.configuratorSelect.push({ header: this.configuratorData[key].header, id: this.configuratorData[key].productPrices[0].product.id })
          if (this.configuratorData[key].header == 'Waschtischanlage')
            this.configuratorSelect.push({ header: this.configuratorData[key].header, id: this.configuratorData[key].productPrices[0].product.id })
        }
        this.$store.dispatch("ADD_CONFIGURATOR_SRC", { select: this.configuratorSelect, room: 'Bad' });
      }
      this.visiblePersonal = this.configuratorData[0].header
      this.select()
      //})


    }

    if (this.msg.room.name == 'Wohnen') {
      temp = WOHNEN_CONFIG
      /*
      axios.get('https://us-central1-cadman-63dc7.cloudfunctions.net/getRoomBookTest?configuratorId=824c832a-545b-4d50-8d7d-c59e5c134381')
        .then((result) => {
          temp = result.data
        })
        .finally(() => {
      */
      for (let i = 0; i < temp.length; i++)
        for (let j = 0; j < temp[i].length; j++)
          arr.push(temp[i][j])

      for (let key in arr) {
        if (arr[key].id == this.msg.start) {
          arr.unshift(...arr.splice(key, 1));
        }
      }
      let arrBad = arr
      for (let j in arrBad) {
        for (let key = 0; key < this.msg.info.lineItems.length; key++) {
          if (this.msg.info.lineItems[key].subLineItems[0].productGroup == null) {
            console.log(null)
          }
          else {
            let arr = []
            if (this.msg.info.lineItems[key].id == arrBad[j].id) {
              arr = this.msg.info.lineItems[key].subLineItems[0].productGroup.productPrices
              this.configuratorData.push({ header: this.msg.info.lineItems[key].title, productPrices: arr })
            }
          }
        }
      }
      this.configuratorSelect = this.$store.getters.configurator.wohnen.select
      if (this.configuratorSelect.length == 0) {
        for (let key in this.configuratorData) {
          if (this.configuratorData[key].header == 'Malerarbeiten Wände')
            this.configuratorSelect.push({ header: this.configuratorData[key].header, id: this.configuratorData[key].productPrices[0].product.id })
          if (this.configuratorData[key].header == 'Parkettarbeiten')
            this.configuratorSelect.push({ header: this.configuratorData[key].header, id: this.configuratorData[key].productPrices[0].product.id })
          if (this.configuratorData[key].header == 'Türdrückergarnituren')
            this.configuratorSelect.push({ header: this.configuratorData[key].header, id: this.configuratorData[key].productPrices[0].product.id })
        }
        this.$store.dispatch("ADD_CONFIGURATOR_SRC", { select: this.configuratorSelect, room: 'Wohnen' });
      }
      this.visiblePersonal = this.configuratorData[0].header
      this.select()
      //})
    }

  },
  methods: {
    activeItemSelect(item) {
      this.activeItem = item
      return this.activeItem
    },
    addLink(p, id) {
      this.srcLink = ''
      this.arrIMG = []
      if (this.msg.room.name == 'Bad')
        this.configuratorSelect = this.$store.getters.configurator.bad.select
      if (this.msg.room.name == 'Wohnen')
        this.configuratorSelect = this.$store.getters.configurator.wohnen.select
      let f = false
      if (this.configuratorSelect.length == 0)
        this.configuratorSelect.push({ header: p, id: id })
      else {
        for (let key in this.configuratorSelect) {
          if (this.configuratorSelect[key].header == p) {
            this.configuratorSelect[key].id = id
            f = true
          }
        }
        if (f == false) {
          this.configuratorSelect.push({ header: p, id: id })
        }
      }
      for (let key in this.configuratorSelect)
        this.arrIMG.push(this.configuratorSelect[key].id)
      if (this.msg.room.name == 'Bad')
        this.link = '10678-Bad'
      if (this.msg.room.name == 'Wohnen')
        this.link = '9960-Schlafen 01'
      this.arrIMG.sort()
      let link = ''
      for (let key in this.arrIMG)
        link = link + '-' + this.arrIMG[key]
      this.link = this.link + link
      axios.get('https://cadman-63dc7.firebaseio.com/ConfiguratorsData/.json')
        .then((result) => {
          this.imgData = result.data
        })
        .finally(() => {
          for (let key in this.imgData)
            if (key == this.link)
              this.srcLink = this.imgData[key]
          this.$store.dispatch("ADD_CONFIGURATOR_SRC", { src: this.srcLink, select: this.configuratorSelect, room: this.msg.room.name });
        })
    },
    nameChange(name) {
      let arr = name.split(',')
      return arr[1]
    },
    changeFloor(floorID, index) {
      this.pickedFloor = floorID
      for (let key in this.activeFloors)
        this.activeFloors[key] = false
      this.activeFloors[index] = true
      this.pushPhoto()
    },
    changeWall(wallID, index) {
      this.pickedWall = wallID
      for (let key in this.activeWalls)
        this.activeWalls[key] = false
      this.activeWalls[index] = true
      this.pushPhoto()
    },
    pushPhoto() {
      let code = this.msg.flat + '-' + this.pickedWall + '-' + this.pickedFloor
      axios.get(urlIMG)
        .then((response) => {
          this.dataIMG = response.data;
        })
        .finally(() => {
          this.moodboardIMG = 'http://garmonia.site/_garmonia/temp/crown/no-photo.jpg'
          for (let key in this.dataIMG) {
            if (key == code)
              this.moodboardIMG = this.dataIMG[key]
          }
        })
    },

    nameChangeFirst(name) {
      let arr = name.split(',')
      return arr[0]
    },
    nameChangeLast(name) {
      let arr = name.split(',')
      return arr[1]
    },
    nameChangeThree(name) {
      let arr = name.split(',')
      return arr[2]
    },

    select() {
      var StoreSelect
      if (this.msg.room.name == 'Bad')
        StoreSelect = this.$store.getters.configurator.bad.select
      if (this.msg.room.name == 'Wohnen')
        StoreSelect = this.$store.getters.configurator.wohnen.select
      //var tempQuery = ''

      for (let i in StoreSelect) {
        if (StoreSelect[i].id == 77925) {
          this.$store.dispatch("ADD_COLOR", 'color:black');
        }
        if (StoreSelect[i].id == 75002) {
          this.$store.dispatch("ADD_COLOR", 'color:silver');
        }
      }

      for (let i in StoreSelect) {
        if (StoreSelect[i].id == 77895) {
          this.$store.dispatch("ADD_COLORWOHNEN", 'color:black');
        }
        if (StoreSelect[i].id == 77882) {
          this.$store.dispatch("ADD_COLORWOHNEN", 'color:silver');
        }
      }

      var arrQuery = []
      var arrSelect = []

      for (let key in this.msg.info.lineItems) {
        if (this.msg.info.lineItems[key].subLineItems[0].productGroup == null) {
          console.log(null)
        }
        else {
          let arr = this.msg.info.lineItems[key].subLineItems
          for (let i in arr) {
            let arrProduct = arr[i].productGroup.productPrices
            for (let k in arrProduct) {
              for (let keyStore in StoreSelect) {
                let picked = StoreSelect[keyStore].id

                if (picked == arrProduct[k].product.id) {

                  let query =
                    'https://us-central1-cadman-63dc7.cloudfunctions.net/returnSelecedItems?' +
                    'localStorage=' + localStorage.getItem('crown') +
                    '&flatId=' + this.msg.flat +
                    '&room=' + this.msg.room.name +
                    '&datatime=' + moment().unix() +
                    '&productGroup=' + arr[i].productGroup.id +
                    '&name=' + arrProduct[k].product.name +
                    '&priceInCents=' + arrProduct[k].priceInCents +
                    '&defaultQuantity=' + arr[i].defaultQuantity +
                    '&productId=' + arrProduct[k].product.id +
                    '&subLineItems=' + arr[i].id +
                    '&lineItems=' + this.msg.info.lineItems[key].id +
                    '&productPriceId=' + arrProduct[k].id

                  arrQuery.push({ productId: arrProduct[k].product.id, query: query })
                  arrSelect.push({ subLineItemsName: arr[i].title, productID: arrProduct[k].product.id, img: arrProduct[k].product.images[0] })
                }
              }
            }
          }
        }
      }

      var uniq = {}
      var arrFiltered = arrQuery.filter(obj => !uniq[obj.productId] && (uniq[obj.productId] = true));
      this.$store.dispatch("ADD_ZQUERY", arrFiltered);


      var uniqSelect = {}
      var arrSelectFilter = arrSelect.filter(obj => !uniqSelect[obj.productID] && (uniqSelect[obj.productID] = true));


      //var arrSelectFilter = Array.from(new Set(arrSelect));
      this.$store.dispatch("ADD_SELECT", { value: arrSelectFilter, room: this.msg.room.name })

      this.loader = true


      /*

            for (let key in arrFiltered) {
              axios.get(arrFiltered[key].query)
                .then(() => {

                })
                .catch(err => {
                  alert('Error ' + err)
                })
                .finally(() => {
                  console.log(arrFiltered[key].query)
                  if (key == arrFiltered.length - 1) {
                    this.loader = true
                  }
                })
            }

      */


    }
  }
}
</script>

<style scoped>
.moodboard {
  overflow-y: auto;
  color: #fff;
  height: auto;
}
.moodboard__check--panel {
  display: inline-flex;
}
img {
  max-height: 50vh;
}
.moodboard__img {
  width: 100%;
}
.moodboard__check--item {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  margin: 0.5em;
  min-width: 20%;
  position: relative;
  padding: 1em;
  color: #fff;
  /*background-color: #b4afae;*/
}
.moodboard__check--item-border {
  color: #323a41;
}
.moodboard__info--img img {
  width: 40px;
  height: 40px;
}
.moodboard__info--img.turd img {
  object-fit: contain;
  background: #fff;
}
.moodboard__check--item-text {
  font-size: 0.8em;
  margin-top: 1em;
  width: 100px;
}
.moodboard__img--button {
  margin: 1em;
}

button {
  border: 0;
  padding: 0.8em;
  padding-left: 1.2em;
  padding-right: 1.2em;
  background: #fff;
  text-transform: uppercase;
}
button.select {
  border: 1px solid #323a41;
  padding-left: 1em;
  padding-right: 1em;
}
button:hover.select {
  border: 1px solid #323a41;
  background: #323a41;
  padding-left: 1em;
  padding-right: 1em;
}
.round {
  border-radius: 40px;
  border: 1px solid #fff;
}
.p__header {
  /*width: 70%;*/
  /*background-color: #b4afae;*/
  margin: 0 auto;
  padding-top: 1em;
}
.none {
  display: none;
}

.menu__personal {
  display: flex;
  justify-content: center;
}
.personalisiren {
  font-size: smaller;
  font-weight: 100;
}
.personalisiren p {
  letter-spacing: 0.2em;
  padding-top: 0;
  padding-bottom: 0;
}
.menu__personal--item {
  padding: 1em;
}
.moodboard__check--header p {
  text-transform: uppercase;
  letter-spacing: 0.2em;
}
.pointer:hover {
  color: #ad8059;
}
.pointer:active {
  color: #ad8059;
}
.pointer-active {
  color: #ad8059;
}
.moodboard p {
  color: #fff;
  font-size: 10pt;
}
</style>
