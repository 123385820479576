<template>
  <div class="crown__kontakt">
    <div id="bg">
      <img :src="img" alt="" />
    </div>
    <Menu />

    <div class="container__form">
      <div class="crown__house--block-header">
        <div class="crown__house--side">
          <div class="crown__house--side-home">
            <div class="crown__house--side-homename">strandkai</div>
            <div class="crown__house--side-homeside">&nbsp;</div>
            <div class="crown__house--side-homepage">
              <router-link class="menu__logo" to="/">
                Kontakt &#9660;
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="outerContainerKontakt">
        <div class="formContainerKontakt" v-if="sended == true">
          <div class="formHeadline">
            <p class="headline">
              Vielen Dank für Ihr Interesse - wir melden uns schnellstmöglich
              bei Ihnen...
            </p>
          </div>
        </div>

        <div class="formContainerKontakt" v-if="sended == false">
          <div class="formHeadline">
            <p class="headline">Kontakt</p>
          </div>

          <form
            id="contactForm"
            class="form"
            method="post"
            action=""
            onsubmit=""
          >
            <div class="row__kontakt">
              <div class="row__kontakt--col-1">
                <p class="info__form">
                  Gerne beantworten wir Ihnen persönlich alle Fragen zu unserem
                  Unternehmen, unserem Leistungsspektrum und unseren Projekten.
                  Nehmen Sie mit uns Kontakt auf.
                </p>
                <input
                  type="hidden"
                  name="contactRegistrationSiteId"
                  value="0"
                />
                <div class="formInput">
                  <input
                    id="contactName"
                    class="error"
                    type="text"
                    placeholder="Name"
                    value=""
                    name="contactName"
                    autocorrect="off"
                    autocapitalize="off"
                    spellcheck="false"
                    v-model="contactName"
                  />
                </div>
                <div class="formInput">
                  <input
                    id="contactEmail"
                    type="email"
                    placeholder="E-Mail"
                    value=""
                    name="contactEmail"
                    autocorrect="off"
                    autocapitalize="off"
                    spellcheck="false"
                    v-model="contactEmail"
                  />
                </div>
                <div class="formInput">
                  <input
                    id="contactWohnung"
                    type="text"
                    placeholder="Wohnung"
                    value=""
                    name="contactWohnung"
                    autocorrect="off"
                    autocapitalize="off"
                    spellcheck="false"
                    v-model="contactWohnung"
                  />
                </div>

              </div>
              <div class="row__kontakt--col-2">
                <div class="formInput">
                  <textarea
                    id="contactMessage"
                    placeholder="Nachricht"
                    name="contactMessage"
                    v-model="contactMessage"
                  ></textarea>
                </div>
                <div class="formNotice">
                  <div>
                    <input id="terms" type="checkbox" v-model="terms" />
                  </div>
                  <div>
                    <label for="terms">
                      <p>
                        Ja, ich habe die
                        <router-link
                          :to="{ name: 'Datenschutz' }"
                          class="link-daten"
                          >"allgemeinen Geschäftsbedingungen"</router-link
                        >
                        gelesen und akzeptiere diese
                      </p>
                    </label>
                  </div>
                </div>
                <div class="formInput formButton">
                  <button
                    type="submit"
                    :disabled="isDisabled"
                    v-on:click="
                      sendEmail(
                        contactName,
                        contactEmail,
                        contactWohnung,
                        contactMessage,
                        contactCode,
                        picked,
                        picked
                      )
                    "
                  >
                    Absenden
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="crown__house--block">
      <div class="crown__house--side">
        <div class="crown__house--side-home">
          <div class="crown__house--side-homename">&nbsp;</div>
          <div class="crown__house--side-homeside">&nbsp;</div>
          <div>&nbsp;</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from './../components/Menu'
import imgOst from './../assets/kontakt/back-kontakt.jpg'
import axios from 'axios';

export default {
  data: function () {
    return {
      img: imgOst,
      terms: false,
      temp: [],
      sended: false,

      contactName: '',
      contactEmail: '',
      contactCode: '',
      contactWohnung: '',
      contactMessage: '',
      picked: '',
    };
  },
  name: 'Kontakt',
  props: {
    msg: String
  },
  components: {
    Menu
  },
  computed: {
    isDisabled: function () {
      if (this.contactName != '' && this.contactEmail != '' && this.terms != false && this.contactWohnung != '') {
        return false
      }
      else
        return true;
    }
  },
  methods: {
    sendEmail(contactName, contactEmail, contactWohnung, contactMessage, contactCode, picked1, picked2) {
      this.sended = true
      axios.get('https://us-central1-cadman-63dc7.cloudfunctions.net/sendKontaktFormInfo?Name=' +
        contactName +
        '&Email=' +
        contactEmail +
        '&Wohnung=' +
        contactWohnung +
        '&Nachricht=' +
        contactMessage +
        '&Telefon=' +
        contactCode +
        '&Service1=' +
        picked1 +
        '&Service2=' +
        picked2)
        .then((result) => {
          this.temp = result.data
        })
        .finally(() => {

        })
    }

  }
}
</script>

<style scoped>
.crown__house--block button {
  padding: 0;
  margin: 1em;
  border: 0 solid #fff;
  background-color: rgba(255, 255, 255, 0);
  text-shadow: 1px 1px 1px #323a41;
}
.crown__house--background {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -100;
  filter: blur(10px);
}
.crown__img {
  position: fixed;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.crown__img img {
  height: 100vh;
}
.crown__house--block {
  position: fixed;
  width: 100%;
  bottom: 2em;
}
.crown__house--side {
  display: flex;
  justify-content: center;
  bottom: 2em;
  margin: 0 auto;
  width: 100%;
}
.crown__house--side .crown__house--side-home {
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  bottom: 2em;
  width: 1178px;
  justify-content: space-between;
  font-family: "35-FTR", sans-serif;
  box-sizing: border-box;
  height: 35px;
  align-items: center;
}
.crown__house--side-homeside button {
  margin: 0;
  margin: 0em 0.5em 0em 0.5em;
  text-shadow: 0px 0px;
}
.crown__houses--side-homeside button:focus {
  outline: none;
  border-bottom: 1px solid #000;
}
.crown__house--side-homename {
  text-transform: uppercase;
}

.pointer {
  cursor: pointer;
}
.outline-none {
  outline: none;
}

#bg {
  position: fixed;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#bg img {
  height: 100vh;
  width: 100vw;
}
.container__form {
  position: fixed;
  overflow-y: auto;
  bottom: 4em;
  width: 100%;
}
.outerContainerKontakt {
  margin: 0 auto;
}
.formContainerKontakt {
  width: 1178px;
  position: relative;
  height: auto;
  display: block;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(4px);
  padding-top: 3em;
  padding-bottom: 3em;
  height: 400px;
  overflow-y: auto;
}
form#contactForm {
  margin: 0 auto;
}
.formHeadline {
  padding-left: 8em;
  padding-right: 8em;
  margin: 0 auto;
}
p {
  margin: 0;
  padding: 0;
  font-size: 0.8em;
  padding-bottom: 0.2em;
  color: #0c0f12;
}
.formContainerKontakt .formHeadline p {
  margin: 0;
  padding: 0;
  font-size: 1em;
  padding-bottom: 1.5em;
  color: #0c0f12;
}
.formContainerKontakt .formHeadline p.headline {
  font-size: 24px;
  padding-bottom: 1.5em;
  color: #0c0f12;
}
.form .formInput {
  width: 100%;
  height: auto;
  padding: 0.8em 0;
  margin: 0;
  display: flex;
  position: relative;
  white-space: pre-line;
}
.form .formInput input,
.form .formInput textarea {
  display: block;
  width: 100%;
  height: 2.4em;
  line-height: 2.4em;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1.25em;
  border: 1px solid rgb(133, 133, 133);
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 0;
  outline: none;
  position: relative;
  color: #0c0f12;
}
.form .formInput textarea {
  height: 140px;
}
.form .formInput input:focus,
.form .formInput textarea:focus {
  color: #0c0f12;
}
.formInput.simpleButton input {
  background: rgba(255, 255, 255, 0);
}
.form .formInput.formButton input {
  border: 1px solid rgb(133, 133, 133);
  color: #0c0f12;
  cursor: pointer;
}
.form .formInput.simpleButton {
  padding: 0;
  margin: 0;
  width: auto;
  height: auto;
  line-height: normal;
  display: inline-block;
  white-space: normal;
}
.form .formInput.simpleButton input {
  line-height: normal;
  width: auto;
  height: auto;
  display: inline;
  border: 0;
  color: #0c0f12;
  padding: 0;
  margin: 0;
  text-decoration: underline;
  cursor: pointer;
}
::placeholder {
  color: #0c0f12;
}
.form input[type="button"] {
  margin-top: 0.9em;
}
.form .formInput.disabledInput {
  opacity: 0.6;
}
.form .formInput.disabledInput .loginPasswordView {
  z-index: -1;
}
.form .formInput .loginPasswordView {
  font-size: 0.8em;
  width: 3em;
  text-align: center;
  height: 2.4em;
  line-height: 2.4em;
  position: absolute;
  top: 1.25em;
  right: 0;
  cursor: pointer;
}
.form .formInput .loginPasswordView:before {
  content: "\e9ce";
}
.form .formInput .loginPasswordView.showPassword:before {
  content: "\e9d1";
}
.form .formInput button {
  width: 100%;
  text-transform: uppercase;
  height: 32px;
}
::placeholder {
  font-family: "35-FTR", sans-serif;
  color: #ccc;
  font-size: 1em;
}
.crown__house--side-homename {
  text-transform: uppercase;
  font-weight: bold;
  padding-left: 2em;
}
.crown__house--side-homepage {
  padding-right: 2em;
}
.crown__house--side-homepage a {
  color: #323a41;
  font-weight: 300;
}

.row__kontakt {
  display: grid;
  grid-template-columns: 50% 50%;
}
.row__kontakt--col-1 {
  padding-left: 8em;
  padding-right: 2em;
}
.row__kontakt--col-2 {
  padding-left: 2em;
  padding-right: 8em;
}
.info__form {
  font-size: 18px;
  padding-bottom: 2em;
}
.formNotice {
  padding-top: 0em;
  padding-bottom: 12px;
}

.formInput.formButton button:disabled {
  color: rgba(16, 16, 16, 0.3) !important;
}

.link-daten {
  color: #323a41;
  text-decoration: underline;
  font-size: 15px;
  letter-spacing: 0 !important;
}
.formNotice p {
  font-size: 15px;
  padding-left: 0.5em;
}
.formNotice input {
  margin-top: 5px;
}

.formNotice {
  display: flex;
}

textarea {
  font-family: "35-FTR", sans-serif;
  font-size: 15px;
}
</style>
